import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins/element.js';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 导入全局样式表
import './assets/css/global.css';
import { Api } from '@/assets/js/api.js';

import axios from 'axios';
Vue.use(ElementUI);
axios.defaults.baseURL = Api.apiDomain;
axios.interceptors.request.use(config => {
    config.headers.Authorization = window.sessionStorage.getItem('token');
    return config;
});
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
