<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>区域划分管理</el-breadcrumb-item>
      <el-breadcrumb-item>区域划分列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <big-area ref="bigChild" @big-child="initBig"></big-area>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="请输入查询关键字"
            width="20px"
            v-model="key"
            type="text"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加小区</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="smallAreaList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="大区名称" prop="areaName"></el-table-column>
        <el-table-column
          label="小区名称"
          prop="smallAreaName"
        ></el-table-column>
        <el-table-column label="小区经理" prop="nameCn"></el-table-column>
        <el-table-column width="1100px" label="区域内经销商">
          <template slot-scope="scope">
            <el-tag
              :key="tag"
              v-for="tag in scope.row.dealerCode"
              :disable-transitions="false"
            >
              {{ tag }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
            ></el-button>
            <el-popconfirm
              style="margin-left: 10px"
              title="确定永久删除吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="deleteById(scope.row.id)"
            >
              <el-button
                slot="reference"
                type="danger"
                icon="el-icon-delete"
                size="mini"
              ></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 修改小区 -->
    <el-dialog
      :close-on-click-modal="false"
      title="修改小区"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="closeEditDialog"
    >
      <el-form
        ref="editFormRef"
        :model="editForm"
        :rules="editFormRules"
        label-width="120px"
      >
        <el-form-item label="小区名称" prop="smallAreaName">
          <el-input v-model="editForm.smallAreaName"></el-input>
        </el-form-item>
        <el-form-item label="小区经理" prop="nameCn">
          <el-select
            v-model="editForm.smallManagerId"
            placeholder="请选择小区经理"
            filterable
          >
            <el-option
              v-for="item in smallManagerList"
              :key="item.userId"
              :label="item.nameCn"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域内经销商" prop="dealerCode">
          <el-tag
            :key="tag"
            v-for="tag in editForm.dealerCode"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm('edit')"
            @blur="handleInputConfirm('edit')"
          >
          </el-input>
          <el-button
            v-else
            class="el-icon-circle-plus-outline"
            size="small"
            @click="showInput"
            type="success"
            >新增经销商</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEditDialog">取 消</el-button>
        <el-button type="primary" @click="updateSmallArea">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增小区-->
    <el-dialog
      :close-on-click-modal="false"
      title="添加小区"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="closeAddDialog"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="editFormRules"
        label-width="100px"
      >
        <el-form-item label="角色" prop="nameCn">
          <saleOrAfter
            ref="roleAddChild"
            @role-child="initAddRole"
          ></saleOrAfter>
        </el-form-item>
        <el-form-item label="小区名称" prop="smallAreaName">
          <el-input v-model="addForm.smallAreaName"></el-input>
        </el-form-item>
        <el-form-item label="小区经理" prop="nameCn">
          <el-select
            v-model="addForm.smallManagerId"
            placeholder="请选择小区经理"
            filterable
          >
            <el-option
              v-for="item in smallManagerList"
              :key="item.userId"
              :label="item.nameCn"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域内经销商" prop="dealerCode">
          <el-tag
            :key="tag"
            v-for="tag in addForm.dealerCode"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm('add')"
            @blur="handleInputConfirm('add')"
          >
          </el-input>
          <el-button
            v-else
            class="el-icon-circle-plus-outline"
            size="small"
            @click="showInput"
            type="success"
            >新增经销商</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSmallArea">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue'
import BigArea from '../common/BigArea.vue'

export default {

  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter, BigArea },
  data() {
    // 这里存放数据
    return {
      addForm: {
        smallAreaName: null,
        smallManagerId: null,
        dealerCode: [],
        roleId: null
      },
      addDialogVisible: false,
      editForm: {
        id: null,
        nameCn: null,
        smallManagerId: null,
        dealerCode: [],
        smallAreaName: null
      },
      editFormRules: {
        smallAreaName: [
          { required: true, message: '请选择小区名称', trigger: 'blur' }
        ]
      },
      key: null,
      loading: false,
      editDialogVisible: false,
      smallAreaList: [],
      smallManagerList: [],
      roleId: null,
      areaId: null,
      inputValue: null,
      inputVisible: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    closeAddDialog() {
      this.addDialogVisible = false
    },
    initAddRole(data) {
      this.addForm.roleId = data
      this.getAllSmallManager(data)
    },
    async addSmallArea() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          this.addForm.dealerCode = this.addForm.dealerCode.join(',')
          const { data: res } = await this.$http.post('/ma/area/smallArea', this.addForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getSmallAreaList()
            this.addDialogVisible = false
            this.inputVisible = false
            this.inputValue = null
          } else {
            this.$message.error(res.msg)
          }
        }
      })
      this.editDialogVisible = false
    },
    async deleteById(id) {
      const { data: res } = await this.$http.delete(`/ma/area/smallArea/${id}`)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.loading = true
        this.getSmallAreaList()
      } else {
        this.$message.error(res.msg)
      }
    },
    searchList() {
      this.loading = true
      this.getSmallAreaList()
    },
    updateSmallArea() {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          this.editForm.dealerCode = this.editForm.dealerCode.join(',')
          const { data: res } = await this.$http.put('/ma/area/smallArea/update', this.editForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getSmallAreaList()
            this.editDialogVisible = false
            this.inputVisible = false
            this.inputValue = null
          } else {
            this.$message.error(res.msg)
          }
        }
      })
      this.editDialogVisible = false
    },
    async getAllSmallManager(data) {
      const { data: res } = await this.$http.get('/ma/user/getAllSmallManager', { params: { roleId: data } })
      this.smallManagerList = res.data
    },
    showEditDialog(data) {
      this.getAllSmallManager(this.roleId)
      this.editDialogVisible = true
      this.editForm.dealerCode = data.dealerCode
      this.editForm.smallAreaName = data.smallAreaName

      this.editForm.nameCn = data.nameCn
      this.editForm.smallManagerId = data.smallManagerId
      this.editForm.id = data.id
    },
    closeEditDialog() {
      this.editDialogVisible = false
      this.inputValue = null
      this.inputVisible = false
      this.editForm.dealerCode = null
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    async handleInputConfirm(type) {
      let dealerCode = []
      let role = null
      if (type === 'edit') {
        dealerCode = this.editForm.dealerCode
        role = this.roleId
      } else {
        dealerCode = this.addForm.dealerCode
        role = this.addForm.roleId
      }
      if (type === 'edit' && this.roleId === null) {
        this.$message.info('请先选择角色')
        return
      }
      if (type === 'add' && this.addForm.roleId === null) {
        this.$message.info('请先选择角色')
        return
      }
      if (this.inputValue) {
        if (dealerCode !== null && dealerCode.indexOf(this.inputValue) !== -1) {
          this.$message.info('请勿重复添加')
          return
        }
        const { data: res } = await this.$http.get('/ma/dealer/isExist', { params: { dealerCode: this.inputValue, roleId: role } })
        if (res.code === 0) {
          if (this.inputValue) {
            if (type === 'edit') {
              this.editForm.dealerCode.push(this.inputValue)
            } else {
              this.addForm.dealerCode.push(this.inputValue)
            }
          }
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    handleClose(tag) {
      this.editForm.dealerCode.splice(this.editForm.dealerCode.indexOf(tag), 1)
    },
    initRole(data) {
      this.roleId = data
      this.loading = true
      this.getSmallAreaList()
    },
    initBig(data) {
      this.areaId = data
      this.loading = true
      this.getSmallAreaList()
    },
    async getSmallAreaList() {
      const { data: res } = await this.$http.get('/ma/area/smallArea/list', { params: { roleId: this.roleId, areaId: this.areaId, key: this.key } })
      this.smallAreaList = res.data
      this.loading = false
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.loading = true
    this.getSmallAreaList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-icon-circle-plus-outline {
  margin-left: 10px;
}
.el-table {
  margin-top: 10px;
}
</style>
