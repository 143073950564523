<template>
    <div> Volvo DSS 运维系统</div>
</template>

<script>
export default {

}
</script>

<style lang="less">

</style>
