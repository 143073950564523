<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>经销商改善计划管理</el-breadcrumb-item>
      <el-breadcrumb-item>经销商改善计划列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.year"
            placeholder="请选择年份"
            @change="selectYear"
          >
            <el-option
              v-for="item in yearList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.month"
            placeholder="请选择月份"
            @change="getRectifyMatterList"
          >
            <el-option
              v-for="item in monthList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.smallAreaId"
            placeholder="小区经理"
            @change="getRectifyMatterList"
            filterable
            @click.native="handleClick"
          >
            <el-option
              v-for="item in smallAreaList"
              :key="item.id"
              :label="item.smallAreaName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.dealerCodes"
            placeholder="经销商"
            @change="getRectifyMatterList"
            filterable
            @click.native="handleClick1"
          >
            <el-option
              v-for="item in dealerCodes"
              :key="item.dealerCodes"
              :label="item.dealerCodes"
              :value="item.dealerCodes"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.state"
            placeholder="状态"
            @change="getRectifyMatterList"
            filterable
          >
            <el-option
              v-for="item in stateList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.type"
            placeholder="类型"
            @change="getRectifyMatterList"
            filterable
          >
            <el-option
              v-for="item in presetTypeList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4.6">
          <div class="block">
            <span class="demonstration"></span>
            <el-date-picker
              v-model="queryInfo.query.date"
              type="date"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="23:59:59"
              @change="getRectifyMatterList"
              placeholder="截止时间">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="4.6">
          <div class="block">
            <span class="demonstration"></span>
            <el-date-picker
              v-model="queryInfo.query.createDate"
              type="date"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="23:59:59"
              @change="getRectifyMatterList"
              placeholder="创建时间">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="exportData">导出数据
          </el-button
          >
        </el-col>
      </el-row>
      <el-table :data="rectifyMattersContentList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="整改年份" prop="year"></el-table-column>
        <el-table-column label="整改月份" prop="month"></el-table-column>
        <el-table-column
          width="100px"
          label="创建时间"
          prop="createTime"
        ></el-table-column>
        <el-table-column
          width="160px"
          label="截止时间"
          prop="deadline"
        ></el-table-column>
        <el-table-column
          width="260px"
          label="整改项"
          prop="title"
        ></el-table-column>
        <el-table-column
          width="260px"
          label="整改目标"
          prop="content"
        ></el-table-column>
        <el-table-column label="经销商" prop="dealerCodes"></el-table-column>
        <el-table-column label="小区经理" prop="smallManagerName"></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="状态" prop="state"></el-table-column>
        <el-table-column label="图片" width="180px">
          <template slot-scope="scope">
            <el-image style="width: 150px; height: 90px"
                      :src="scope.row.url[0]"
                      :preview-src-list="scope.row.url">
              <div slot="error" class="image-slot" style="width: auto;height: auto">
                <i class="el-icon-picture-outline">暂无图片</i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="openDetail(scope.row.id)"
            >查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      title="经销商改善计划完成情况"
      :visible.sync="detailDialogVisible"
      width="70%"
      @close="closeDetailDialog"
    >
      <el-table :data="detailList" stripe border v-loading="loading">
        <el-table-column label="内容" prop="content"></el-table-column>
        <el-table-column label="操作状态" prop="state"></el-table-column>
        <el-table-column label="操作时间" prop="createTime"></el-table-column>
        <el-table-column label="图片" width="180px">
          <template slot-scope="scope">
            <el-image style="width: 150px; height: 90px"
                      :src="scope.row.url[0]"
                      :preview-src-list="scope.row.url">
              <div slot="error" class="image-slot" style="width: auto;height: auto">
                <i class="el-icon-picture-outline">暂无图片</i>
              </div>
            </el-image>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue'
import { Api } from '@/assets/js/api.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter },
  data() {
    // 这里存放数据
    return {
      ossDomain: Api.ossDomain,
      fullscreenLoading: false,
      detailDialogVisible: false,
      fileList: [],
      smallAreaList: [],
      stateList: [],
      exportDialogVisible: false,
      haedUserList: [],
      presetTypeList: [],
      smallManagerList: [],
      bigManagerList: [],
      dealerCodes: [],
      detailList: [],
      addForm: {
        imgList: [],
        year: null,
        month: null,
        roleId: null,
        checkDealers: [],
        handlerId: null,
        typeId: null,
        deadline: null,
        content: null,
        target: null,
        checkType: null
      },
      addDialogVisible: false,
      loading: false,
      yearList: [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      rectifyMattersContentList: [],
      publisher: null,
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          year: null,
          month: null,
          roleId: null,
          dealerCodes: null,
          date: null,
          createDate: null,
          type: null,
          state: null,
          smallAreaId: null
        }
      },
      total: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async getState() {
      const { data: res } = await this.$http.get('/ma/rectifyMatter/getState')
      this.stateList = res.data
    },
    async getSmallAreaList() {
      const { data: res } = await this.$http.get('/ma/base/getSmallAreaList', { params: { roleId: this.queryInfo.query.roleId } })
      this.smallAreaList = res.data
    },
    async getDealerCodes() {
      const { data: res } = await this.$http.get('/ma/base/dealerCodeBySmallAreaId', { params: { smallAreaId: this.queryInfo.query.smallAreaId } })
      this.dealerCodes = res.data
    },
    handleClick() {
      if (!this.queryInfo.query.roleId) {
        this.$message.info('请选择角色')
      } else {
        this.getSmallAreaList()
      }
    },
    handleClick1() {
      if (!this.queryInfo.query.smallAreaId) {
        this.$message.info('请选择小区经理')
      } else {
        this.getDealerCodes()
      }
    },
    initAddRole(data) {
      this.addForm.roleId = data
      this.getDealerCodes()
    },
    async getHeadUserList() {
      const { data: res } = await this.$http.get('/ma/user/headList')
      this.haedUserList = res.data
    },
    async getTypeList() {
      const { data: res } = await this.$http.get('/ma/rectifyMatter/getRectifyType')
      this.presetTypeList = res.data
    },
    closeAddDialog() {
    },
    async openDetail(data) {
      this.detailDialogVisible = true
      this.loading = true
      const { data: res } = await this.$http.get(`ma/rectifyMatter/detailList/${data}`)
      this.detailList = res.data
      for (let i = 0; i < this.detailList.length; i++) {
        if (this.detailList[i].url) {
          const split = this.detailList[i].url.split(',')
          for (let i = 0; i < split.length; i++) {
            if (split[i]) {
              split[i] = this.ossDomain + split[i]
            }
          }
          this.detailList[i].url = split
        } else {
          this.detailList[i].url = ''
        }
      }
      this.loading = false
    },
    async exportData() {
      // const oriUrl = window.location.origin + window.location.pathname
      const url = Api.apiDomain + '/ma/rectifyMatter/exportData'
      const { data: res } = await this.$http.post('/ma/rectifyMatter/exportDataParam', this.queryInfo.query)
      if (res.code === -20048) {
        this.$message.error(res.msg)
      } else {
        window.open(url, '_self')
      }
    },
    selectYear() {
      this.queryInfo.query.month = null
      this.getRectifyMatterList()
    },
    initRole(data) {
      this.queryInfo.query.roleId = data
      this.getRectifyMatterList()
    },
    async getRectifyMatterList() {
      this.loading = true
      const { data: res } = await this.$http.post('/ma/rectifyMatter/content/list', this.queryInfo)
      this.rectifyMattersContentList = res.data.content
      this.rectifyMattersContentList = res.data.content
      for (let i = 0; i < this.rectifyMattersContentList.length; i++) {
        if (this.rectifyMattersContentList[i].url) {
          const split = this.rectifyMattersContentList[i].url.split(',')
          for (let i = 0; i < split.length; i++) {
            if (split[i]) {
              split[i] = this.ossDomain + split[i]
            }
          }
          this.rectifyMattersContentList[i].url = split
        } else {
          this.rectifyMattersContentList[i].url = ''
        }
      }
      this.total = res.data.totalElements
      this.loading = false
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getRectifyMatterList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getRectifyMatterList()
    },
    selectPublisher() {
      this.$emit('', this.value)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getState()
    this.getRectifyMatterList()
    this.getTypeList()
    this.getHeadUserList()
    this.getPublisherName()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
/deep/ .el-table td.el-table__cell div {
  height: 100px;
  overflow: overlay;
}

.el-table {
  margin-top: 10px;
}

/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
  }
}
</style>
