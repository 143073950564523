export const Api = {
    // 测试环境
    // apiDomain: 'https://dcssapi-new-uat.digitalvolvo.com', // 域名
    apiDomain: 'https://dcssapi.digitalvolvo.com', // 域名
    // apiDomain: 'http://127.0.0.1:8081', // 域名
    // apiDomain: 'http://10.129.222.98:8081/',

    // ossDomain: 'https://dcss-file-cdn-uat.digitalvolvo.com' // 文件前缀域名
    ossDomain: 'https://dcss-file-cdn.digitalvolvo.com' // 生产文件前缀域名

};
