<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="bannerList" stripe border>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="正文" prop="content"></el-table-column>
        <el-table-column label="排序" prop="sort"></el-table-column>
        <el-table-column label="跳转" prop="href"> </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <img :src="ossDomain + scope.row.url" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" @click="edit(scope.row)">编辑</el-button>
            <el-popconfirm
              style="margin-left: 10px"
              title="确定删除吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="deleteById(scope.row.id)"
            >
              <el-button slot="reference" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      title="轮播图新增"
      @close="closeAddDialog"
      :visible.sync="addDialogVisible"
      width="50%"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="editFormRules"
        label-width="100px"
      >
        <el-form-item label="角色" prop="role">
          <saleOrAfter
            ref="roleAddChild"
            @role-child="initAddRole"
          ></saleOrAfter>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input type="input" v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="input" v-model="addForm.content"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-select v-model="addForm.sort" placeholder="请选择排序">
            <el-option
              v-for="item in sortList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转" prop="href">
          <el-input type="input" v-model="addForm.href"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            class="upload-demo"
            :on-remove="addHandleRemove"
            :before-upload="beforeUploadImg"
            :http-request="addUploadImg"
            :file-list="addForm.imgList"
            action=""
            list-type="picture"
          >
            <el-button size="small" type="primary" style="float: left"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addBanner">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="轮播图修改"
      @close="closeEditDialog"
      :visible.sync="editDialogVisible"
      width="50%"
    >
      <el-form
        ref="editFormRef"
        :model="editForm"
        :rules="editFormRules"
        label-width="100px"
      >
        <el-form-item label="标题" prop="title">
          <el-input type="input" v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="input" v-model="editForm.content"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-select v-model="editForm.sort" placeholder="请选择排序">
            <el-option
              v-for="item in sortList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转" prop="href">
          <el-input type="input" v-model="editForm.href"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            class="upload-demo"
            :on-remove="handleRemove"
            :before-upload="beforeUploadImg"
            :http-request="uploadImg"
            :file-list="editForm.imgList"
            action=""
            list-type="picture"
          >
            <el-button size="small" type="primary" style="float: left"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateBanner">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue'
import { Api } from '@/assets/js/api.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter },

  data() {
    var checkRole = (rule, value, cb) => {
      if (!this.addForm.roleId) {
        cb(new Error('请选择角色'))
      }
      return cb()
    }
    // 这里存放数据
    return {
      addDialogVisible: false,
      sortList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      addForm: {
        roleId: null,
        title: null,
        content: null,
        sort: null,
        href: null,
        imgList: [],
        url: null
      },
      editForm: {
        id: null,
        title: null,
        content: null,
        sort: null,
        href: null,
        imgList: [],
        url: null
      },
      editFormRules: {
        role: [{ validator: checkRole, trigger: 'blur' }],

        content: [
          { required: true, message: '请填写内容', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请填写标题', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请选择排序', trigger: 'blur' }
        ],
        href: [
          { required: true, message: '请填写跳转链接', trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      bannerList: [],
      roleId: null,
      ossDomain: Api.ossDomain
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    initAddRole(data) {
      this.addForm.roleId = data
    },
    async addBanner() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          if (this.addForm.imgList.length === 0) {
            this.$message.warning('请上传图片')
            return
          }
          this.addForm.url = this.addForm.imgList[0].url.replace(Api.ossDomain, '')
          const { data: res } = await this.$http.post('/ma/banner', this.addForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getBannerList()
            this.addDialogVisible = false
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async updateBanner() {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          if (this.editForm.imgList.length === 0) {
            this.$message.warning('请上传图片')
            return
          }
          this.editForm.url = this.editForm.imgList[0].url.replace(Api.ossDomain, '')
          const { data: res } = await this.$http.put('/ma/banner', this.editForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getBannerList()
            this.editDialogVisible = false
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    closeEditDialog() {
      this.editForm.imgList = []
    },
    closeAddDialog() {
      this.addForm.imgList = []
      this.$refs.addFormRef.resetFields()
    },
    handleRemove(file, fileList) {
      this.editForm.imgList = this.editForm.imgList.filter(item => item !== file)
    },
    addHandleRemove(file, fileList) {
      this.addForm.imgList = this.addForm.imgList.filter(item => item !== file)
    },
    beforeUploadImg(file) {
      // 判断文件格式
      const name = file.name.split('.').pop()
      if (name !== 'png' && name !== 'jpg') {
        this.$message.error('只能选择jpg或者png文件')
        return false
      }
      if (this.editForm.imgList.length >= 1 || this.addForm.imgList.length >= 1) {
        this.$message.error('仅限一张图片')
        return false
      }
    },
    async uploadImg(f) {
      const param = new FormData()
      param.append('file', f.file)

      const { data: res } = await this.$http.post('/ma/base/uploadImg', param)
      res.data.url = Api.ossDomain + res.data.url
      this.editForm.imgList.push(res.data)
    },
    async addUploadImg(f) {
      const param = new FormData()
      param.append('file', f.file)

      const { data: res } = await this.$http.post('/ma/base/uploadImg', param)
      res.data.url = Api.ossDomain + res.data.url
      this.addForm.imgList.push(res.data)
    },
    edit(data) {
      this.editForm.title = data.title
      this.editForm.content = data.content
      this.editForm.sort = data.sort
      this.editForm.href = data.href
      this.editDialogVisible = true
      this.editForm.id = data.id
      const img = {
        uid: data.id,
        url: Api.ossDomain + data.url
      }
      this.editForm.imgList.push(img)
    },
    async deleteById(id) {
      const { data: res } = await this.$http.delete(`/ma/banner/${id}`)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.getBannerList()
      } else {
        this.$message.error(res.msg)
      }
    },
    initRole(data) {
      this.roleId = data
      this.getBannerList()
    },
    async getBannerList() {
      const { data: res } = await this.$http.get('ma/banner/list', { params: { roleId: this.roleId } }) // java
      this.bannerList = res.data
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getBannerList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
img {
  width: 200px;
  height: 100px;
}
</style>
