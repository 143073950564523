<!--  -->
<template>
  <div>
    <el-select
      v-model="roleValue"
      placeholder="请选择角色"
      @change="selectRole"
    >
      <el-option
        v-for="item in roleOptions"
        :key="item.id"
        :label="item.roleName"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <span>-</span>
    <el-select
      v-model="positionValue"
      placeholder="请选择职位"
      @change="selectPosition"
    >
      <el-option
        v-for="item in positionOptions"
        :key="item.id"
        :label="item.positionName"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  props: ['roleId', 'positionId'],
  // 监控data中的数据变化
  watch: {
    roleId: {
      async handler(val) {
        this.roleValue = val
        const { data: res } = await this.$http.get('/ma/base/positionList', { params: { roleId: this.roleValue } })
        this.positionOptions = res.data
      },
      immediate: true
    },
    positionId: {
      handler(val) {
        this.positionValue = val
      },
      immediate: true
    }
  },
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      roleOptions: [],
      roleValue: null,
      positionOptions: [],
      positionValue: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 方法集合
  methods: {
    clear() {
      this.roleValue = null
      this.positionValue = null
    },
    async getRoleList() {
      const { data: res } = await this.$http.get('/ma/base/roleList')
      this.roleOptions = res.data
    },
    async selectRole() {
      this.postitionValue = null
      const { data: res } = await this.$http.get('/ma/base/positionList', { params: { roleId: this.roleValue } })
      this.positionOptions = res.data
      const data = {
        roleId: this.roleValue,
        positionId: this.postitionValue
      }
      this.$emit('role-child', data)
    },
    selectPosition() {
      const data = {
        roleId: this.roleValue,
        positionId: this.positionValue
      }
      this.$emit('position-child', data)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getRoleList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
</style>
