<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>版本管理</el-breadcrumb-item>
      <el-breadcrumb-item>版本修改</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="5">
          <el-form ref="form" :model="versionForm" label-width="80px">
            <el-form-item label="发版时间">
              <el-input disabled v-model="versionForm.time"></el-input>
            </el-form-item>
            <el-form-item
              prop="versionCode"
              :rules="[
                { required: true, message: '版本号不能为空', trigger: 'blur' },
              ]"
              label="当前版本"
            >
              <el-input
                :disabled="disabled"
                v-model="versionForm.versionCode"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-button type="success" @click="updateVersion">发布版本</el-button>
    </el-card>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      versionForm: {
        versionCode: null,
        time: null
      },
      disabled: true
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async updateVersion() {
      if (this.disabled) {
        this.disabled = !this.disabled
      } else {
        this.$refs.form.validate(async valid => {
          if (valid) {
            this.disabled = !this.disabled
            const { data: res } = await this.$http.put(`ma/base/version/${this.versionForm.versionCode}`)
            if (res.code === 0) {
              this.getVersion()
              this.$message.success(res.msg)
            } else {
              this.$message.error(res.msg)
            }
          }
        })
      }
    },
    async getVersion() {
      const { data: res } = await this.$http.get('ma/base/version')
      this.versionForm.versionCode = res.data.version
      this.versionForm.time = res.data.updateTime
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getVersion()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
</style>
