<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>问卷管理</el-breadcrumb-item>
      <el-breadcrumb-item>问卷列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <el-select v-model="queryInfo.query.status" placeholder="请选择状态" clearable @change="getData(true)">
            <el-option label="已发布" :value="true"></el-option>
            <el-option label="未发布" :value="false"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input type="text" placeholder="请输入查询关键字" clearable v-model="queryInfo.query.key">
            <el-button slot="append" icon="el-icon-search" @click="getData(true)"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="info" @click="clearSearch">清空条件</el-button>
          <el-button type="primary" @click="addRecord">添加问卷</el-button>
        </el-col>
      </el-row>
      <el-table :data="list" :loading="loading" stripe border>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="问卷主题" width="360px" prop="questionName" show-overflow-tooltip></el-table-column>
        <el-table-column label="发布人" prop="publishName"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status">已发布</span>
            <span v-else style="color: #409eff;">未发布</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="openDetail(scope.row.id)">查看</el-button>
            <el-popconfirm
              v-if="!scope.row.status"
              style="margin-left: 10px;"
              title="确定发布吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="publishRecord(scope.row)"
            >
              <el-button slot="reference" type="success">发布</el-button>
            </el-popconfirm>
            <el-popconfirm
              v-if="!scope.row.status"
              style="margin-left: 10px;"
              title="确定删除吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="deleteRecord(scope.row.id)"
            >
              <el-button slot="reference" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
function initQuery() {
  return {
    status: null,
    key: "",
  };
}

export default {
  data() {
    return {
      loading: false,
      beginEndDate: null,
      list: [],
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: initQuery(),
      },
      total: null,
    };
  },
  created() {
    this.getData(true);
  },
  methods: {
    clearSearch() {
      this.queryInfo.query = initQuery();
      this.getData(true);
    },
    async getData(refresh = false) {
      if (refresh) {
        this.queryInfo.pageNum = 1;
      }

      const { data: res } = await this.$http.post("/questionnaire/findPage", this.queryInfo);

      if (res.code !== 0) {
        this.$message.error(res.msg);
      } else {
        this.list = res.data.content;
        this.total = res.data.totalElements;
      }
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData(true);
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getData();
    },
    addRecord() {
      this.$router.push({
        path: "/setQuestionnaire",
      });
    },
    async deleteRecord(id) {
      const { data: res } = await this.$http.delete(`/questionnaire/deleteAnswer/${id}`);

      if (res.code === 0) {
        this.$message.success(res.msg);
        this.getData();
      } else {
        this.$message.error(res.msg);
      }
    },

    async publishRecord(record) {
      const { userName } = JSON.parse(window.sessionStorage.getItem("userInfo"));

      const { data: res } = await this.$http.post(`/questionnaire/modifyStatus`, {
        id: record.id,
        publishName: userName,
      });

      if (res.code === 0) {
        this.$message.success(res.msg);
        this.getData();
      } else {
        this.$message.error(res.msg);
      }
    },
    async openDetail(id) {
      this.$router.push({
        path: "/setQuestionnaire",
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-table {
  margin-top: 10px;
}
</style>
