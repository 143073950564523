<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>经销商需求反馈管理</el-breadcrumb-item>
      <el-breadcrumb-item>经销商需求反馈列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <el-select
              v-model="queryInfo.query.year"
              placeholder="请选择年份"
              @change="selectYear"
          >
            <el-option
                v-for="item in yearList"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
              v-model="queryInfo.query.month"
              placeholder="请选择月份"
              @change="getSupportMatterList"
          >
            <el-option
                v-for="item in monthList"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
              v-model="queryInfo.query.publisherId"
              placeholder="部门"
              @change="getSupportMatterList"
              filterable
          >
            <el-option
                v-for="item in publisher"
                :key="item.id"
                :label="item.nameCn"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
              v-model="queryInfo.query.smallAreaId"
              placeholder="小区经理"
              @change="getSupportMatterList"
              filterable
              @click.native="handleClick1"
          >
            <el-option
                v-for="item in smallAreaList"
                :key="item.id"
                :label="item.smallAreaName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
              v-model="queryInfo.query.state"
              placeholder="状态"
              @change="getSupportMatterList"
              filterable
          >
            <el-option
                v-for="item in stateList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
            >
            </el-option>
          </el-select>
        </el-col>
        <!--        <el-col :span="2">-->
        <!--          <el-select-->
        <!--            v-model="queryInfo.query.type"-->
        <!--            placeholder="类型"-->
        <!--            @change="getSupportMatterList"-->
        <!--            filterable-->
        <!--          >-->
        <!--            <el-option-->
        <!--              v-for="item in supportTypeList"-->
        <!--              :key="item.key"-->
        <!--              :label="item.value"-->
        <!--              :value="item.key"-->
        <!--            >-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-col>-->
        <!--        <el-col :span="4.6">-->
        <!--          <div class="block">-->
        <!--            <span class="demonstration"></span>-->
        <!--            <el-date-picker-->
        <!--              v-model="queryInfo.query.date"-->
        <!--              type="date"-->
        <!--              value-format="yyyy-MM-dd HH:mm:ss"-->
        <!--              default-time="23:59:59"-->
        <!--              @change="getSupportMatterList"-->
        <!--              placeholder="截止时间">-->
        <!--            </el-date-picker>-->
        <!--          </div>-->
        <!--        </el-col>-->
        <el-col :span="4.6">
          <el-date-picker
              v-model="createBeginEndDate"
              type="daterange"
              range-separator="至"
              start-placeholder="创建日期"
              end-placeholder="结束日期"
              @change="getSupportMatterList"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '00:00:00']"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4.6">
          <el-date-picker
              v-model="deadlineBeginEndDate"
              type="daterange"
              range-separator="至"
              start-placeholder="截止日期"
              end-placeholder="结束日期"
              @change="getSupportMatterList"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '00:00:00']"
          >
          </el-date-picker>
        </el-col>
        <!--        <el-col :span="4.6">-->
        <!--          <div class="block">-->
        <!--            <span class="demonstration"></span>-->
        <!--            <el-date-picker-->
        <!--              v-model="queryInfo.query.createDate"-->
        <!--              type="date"-->
        <!--              value-format="yyyy-MM-dd HH:mm:ss"-->
        <!--              default-time="23:59:59"-->
        <!--              @change="getSupportMatterList"-->
        <!--              placeholder="创建时间">-->
        <!--            </el-date-picker>-->
        <!--          </div>-->
        <!--        </el-col>-->
        <el-col :span="1">
          <el-button type="info" @click="clearCondition">重置</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="exportData">导出数据
          </el-button
          >
        </el-col>
      </el-row>
      <el-table :data="supportMattersContentList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="整改年份" prop="year"></el-table-column>
        <el-table-column label="整改月份" prop="month"></el-table-column>
        <el-table-column
            width="100px"
            label="创建时间"
            prop="createTime"
        ></el-table-column>
        <el-table-column
            width="160px"
            label="截止时间"
            prop="deadline"
        ></el-table-column>
        <el-table-column
            width="260px"
            label="经销商需求反馈标题"
            prop="title"
        ></el-table-column>
        <el-table-column
            width="260px"
            label="经销商需求反馈内容"
            prop="content"
        ></el-table-column>
        <el-table-column label="支援部门" prop="publisherName"></el-table-column>
        <el-table-column label="小区经理" prop="smallManagerName"></el-table-column>
        <!--        <el-table-column label="类型" prop="type"></el-table-column>-->
        <el-table-column label="状态" prop="state"></el-table-column>
        <el-table-column label="图片" width="180px">
          <template slot-scope="scope">
            <el-image style="width: 150px; height: 90px"
                      :src="scope.row.url[0]"
                      :preview-src-list="scope.row.url">
              <div slot="error" class="image-slot" style="width: auto;height: auto">
                <i class="el-icon-picture-outline">暂无图片</i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="110px">
          <template slot-scope="scope">
            <el-button
                type="primary"
                @click="openDetail(scope.row.id)"
                v-show="scope.row.state === '完成'"
            >查看</el-button>
            <el-button
                type="success"
                @click="reply(scope.row.id)"
                v-show="scope.row.state === '待回复'"
            >回复</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[5, 10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
    <el-dialog
        title="小区经理完成情况"
        :visible.sync="detailDialogVisible"
        width="70%"
        @close="closeDetailDialog"
    >
      <el-table :data="detailList" stripe border v-loading="loading">
        <el-table-column label="内容" prop="reply"></el-table-column>
        <el-table-column label="操作状态" prop="state"></el-table-column>
        <el-table-column label="操作时间" prop="updateTime"></el-table-column>
        <el-table-column label="图片" width="180px">
          <template slot-scope="scope">
            <el-image style="width: 150px; height: 90px"
                      :src="scope.row.url[0]"
                      :preview-src-list="scope.row.url">
              <div slot="error" class="image-slot" style="width: auto;height: auto">
                <i class="el-icon-picture-outline">暂无图片</i>
              </div>
            </el-image>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
        title="回复"
        :visible.sync="replyDialogVisible"
        width="70%"
        @close="replyDetailDialog"
    >
      <el-table :data="detailList" stripe border v-loading="loading">
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="内容" prop="content"></el-table-column>
      </el-table>

      <el-form
          ref="replyFormRef"
          :model="replyFrom"
          label-width="70px"
      >
        <el-form-item label="回复内容" prop="reply">
          <el-input type="textarea" v-model="replyFrom.reply"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="handlerId">
          <el-select
              v-model="replyFrom.supportPeople"
              placeholder="请选择联系人"
              filterable
          >
            <el-option
                v-for="item in supportPeople"
                :key="item.username"
                :label="item.name"
                :value="item.username"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
              class="upload-demo"
              :on-remove="handleRemove1"
              :before-upload="beforeUploadImg1"
              :http-request="uploadImg1"
              :file-list="replyFrom.imgListForBack"
              action=""
              list-type="picture"
          >
            <el-button size="small" type="primary" style="float: left"
            >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">图片只能选择jpg或者png文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="replyDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRemark">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue'
import { Api } from '@/assets/js/api.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter },
  data() {
    // 这里存放数据
    return {
      replyFrom:{
        supportMattersId: null,
        state: "COMPLETE",
        reply: null,
        imgListForBack: [],
        supportPeople: null
      },
      supportPeople: [],
      createBeginEndDate: null,
      deadlineBeginEndDate: null,
      ossDomain: Api.ossDomain,
      fullscreenLoading: false,
      detailDialogVisible: false,
      replyDialogVisible: false,
      replyDetailDialog: false,
      detailList: [],
      smallAreaList: [],
      stateList: [],
      exportDialogVisible: false,
      haedUserList: [],
      supportTypeList: [],
      smallManagerList: [],
      bigManagerList: [],
      dealerCodes: [],
      addForm: {
        imgList: [],
        year: null,
        month: null,
        roleId: null,
        checkDealers: [],
        handlerId: null,
        typeId: null,
        deadline: null,
        content: null,
        target: null,
        checkType: null
      },
      loading: false,
      yearList: [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      supportMattersContentList: [],
      publisher: null,
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          year: null,
          month: null,
          roleId: null,
          publisherId: null,
          deadlineBeginDate: null,
          deadlineEndDate: null,
          createBeginDate: null,
          createEndDate: null,
          type: null,
          state: null,
          smallAreaId: null
        }
      },
      total: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    handleRemove(file, fileList) {
      this.addForm.imgList = this.addForm.imgList.filter(item => item !== file)
    },
    async getPublisherName() {
      const { data: res } = await this.$http.get('/ma/supportMatter/getPublisherName')
      this.publisher = res.data
    },
    async getState() {
      const { data: res } = await this.$http.get('/ma/supportMatter/getState')
      this.stateList = res.data
    },
    async getSupportPeople() {
      const { data: res } = await this.$http.get('/supportMatter/getSupportPeople')
      this.supportPeople = res.data
    },
    async getSmallAreaList() {
      const { data: res } = await this.$http.get('/ma/base/getSmallAreaList', { params: { roleId: this.queryInfo.query.roleId } })
      this.smallAreaList = res.data
    },
    handleClick() {
      this.addForm.checkDealers = []
      if (!this.addForm.roleId) {
        this.$message.info('请选择角色')
      } else {
        this.getDealerCodes()
      }
    },
    handleClick1() {
      if (!this.queryInfo.query.roleId) {
        this.$message.info('请选择角色')
      } else {
        this.getSmallAreaList()
      }
    },
    initAddRole(data) {
      this.addForm.roleId = data
      this.getDealerCodes()
    },
    handleRemove1(file, fileList) {
      this.replyFrom.imgListForBack = this.replyFrom.imgListForBack.filter(item => item !== file)
    },
    async uploadImg1(f) {
      const param = new FormData()
      param.append('file', f.file)

      const { data: res } = await this.$http.post('/ma/base/uploadImg', param)
      res.data.url = Api.ossDomain + res.data.url
      this.replyFrom.imgListForBack.push(res.data)
    },
    beforeUploadImg1(file) {
      // 判断文件格式
      const name = file.name.split('.').pop()
      if (name !== 'png' && name !== 'jpg') {
        this.$message.error('图片只能选择jpg或者png文件')
        return false
      }
    },
    async getHeadUserList() {
      const { data: res } = await this.$http.get('/ma/user/headList')
      this.haedUserList = res.data
    },
    async getTypeList() {
      const { data: res } = await this.$http.get('/ma/supportMatter/getSupportType')
      this.supportTypeList = res.data
    },
    closeAddDialog() {
    },
    async reply(data) {
      this.replyDialogVisible = true
      this.loading = true
      const { data: res } = await this.$http.get(`ma/supportMatter/detailList/${data}`)
      this.replyFrom.supportMattersId = data
      this.detailList = res.data
      this.loading = false
    },

    async addRemark(data) {
      const { data: res } = await this.$http.post('/ma/supportMatter/submitSupportMatters', this.replyFrom)
      if (res.code === 0) {
      this.detailList = res.data
      this.loading = false
      this.replyDialogVisible = false
      this.replyFrom.supportPeople = null
      this.replyFrom.reply = null
      this.replyFrom.imgListForBack = []
      await this.getSupportMatterList()
        this.$message.success(res.msg)
      }else {
        this.$message.error(res.msg)
      }
    },
    async openDetail(data) {
      this.detailDialogVisible = true
      this.loading = true
      const { data: res } = await this.$http.get(`ma/supportMatter/detailList/${data}`)
      this.detailList = res.data
      for (let i = 0; i < this.detailList.length; i++) {
        if (this.detailList[i].url) {
          const split = this.detailList[i].url.split(',')
          for (let i = 0; i < split.length; i++) {
            if (split[i]) {
              split[i] = this.ossDomain + split[i]
            }
          }
          this.detailList[i].url = split
        } else {
          this.detailList[i].url = ''
        }
      }
      this.loading = false
    },
    async exportData() {
      if (this.createBeginEndDate) {
        this.queryInfo.query.createBeginDate = this.createBeginEndDate[0]
        this.queryInfo.query.createEndDate = this.createBeginEndDate[1]
      } else {
        this.queryInfo.query.createBeginDate = null
        this.queryInfo.query.createEndDate = null
      }
      if (this.deadlineBeginEndDate) {
        this.queryInfo.query.deadlineBeginDate = this.deadlineBeginEndDate[0]
        this.queryInfo.query.deadlineEndDate = this.deadlineBeginEndDate[1]
      } else {
        this.queryInfo.query.deadlineBeginDate = null
        this.queryInfo.query.deadlineEndDate = null
      }
      // const oriUrl = window.location.origin + window.location.pathname
      const url = Api.apiDomain + '/ma/supportMatter/exportData'
      const { data: res } = await this.$http.post('/ma/supportMatter/exportDataParam', this.queryInfo.query)
      if (res.code === -20048) {
        this.$message.error(res.msg)
      } else {
        window.open(url, '_self')
      }
    },
    selectYear() {
      this.queryInfo.query.month = null
      this.getSupportMatterList()
    },
    initRole(data) {
      this.queryInfo.query.roleId = data
      this.getSupportMatterList()
    },
    async getSupportMatterList() {
      if (this.createBeginEndDate) {
        this.queryInfo.query.createBeginDate = this.createBeginEndDate[0]
        this.queryInfo.query.createEndDate = this.createBeginEndDate[1]
      } else {
        this.queryInfo.query.createBeginDate = null
        this.queryInfo.query.createEndDate = null
      }
      if (this.deadlineBeginEndDate) {
        this.queryInfo.query.deadlineBeginDate = this.deadlineBeginEndDate[0]
        this.queryInfo.query.deadlineEndDate = this.deadlineBeginEndDate[1]
      } else {
        this.queryInfo.query.deadlineBeginDate = null
        this.queryInfo.query.deadlineEndDate = null
      }
      this.loading = true
      const { data: res } = await this.$http.post('/ma/supportMatter/content/list', this.queryInfo)
      this.supportMattersContentList = res.data.content
      for (let i = 0; i < this.supportMattersContentList.length; i++) {
        if (this.supportMattersContentList[i].url) {
          const split = this.supportMattersContentList[i].url.split(',')
          for (let i = 0; i < split.length; i++) {
            if (split[i]) {
              split[i] = this.ossDomain + split[i]
            }
          }
          this.supportMattersContentList[i].url = split
        } else {
          this.supportMattersContentList[i].url = ''
        }
      }
      this.total = res.data.totalElements
      this.loading = false
    },
    clearCondition() {
      this.queryInfo.query.year = null
      this.queryInfo.query.month = null
      this.queryInfo.query.roleId = null
      this.queryInfo.query.publisherId = null
      this.queryInfo.query.deadlineBeginDate = null
      this.queryInfo.query.deadlineEndDate = null
      this.queryInfo.query.createBeginDate = null
      this.queryInfo.query.createEndDate = null
      this.queryInfo.query.type = null
      this.queryInfo.query.state = null
      this.queryInfo.query.smallAreaId = null
      this.$refs.roleChild.clear()
      this.getSupportMatterList()
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getSupportMatterList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getSupportMatterList()
    },
    selectPublisher() {
      this.$emit('', this.value)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getSupportMatterList()
    this.getTypeList()
    this.getHeadUserList()
    this.getPublisherName()
    this.getState()
    this.getSupportPeople()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
/deep/ .el-table td.el-table__cell div {
  height: 100px;
  overflow: overlay;
}

.el-table {
  margin-top: 10px;
}

/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
  }
}
</style>
