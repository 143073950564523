<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>大区管理</el-breadcrumb-item>
      <el-breadcrumb-item>大区列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
      </el-row>
      <el-table :data="areaList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="大区名称" prop="areaName"></el-table-column>
        <el-table-column label="大区经理" prop="bigManager"></el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 修改大区经理 -->
    <el-dialog
      :close-on-click-modal="false"
      title="修改大区经理"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="closeEditDialog"
    >
      <el-form
        ref="editFormRef"
        :model="editForm"
        :rules="editFormRules"
        label-width="120px"
      >
        <el-form-item label="大区" prop="areaName">
          <el-input v-model="editForm.areaName"></el-input>
        </el-form-item>
        <el-form-item label="大区经理" prop="bigManager">
          <el-select
            v-model="editForm.bigManagerId"
            placeholder="请选择大区经理"
            filterable
          >
            <el-option
              v-for="item in bigManagerList"
              :key="item.userId"
              :label="item.nameCn"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateArea">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import SaleOrAfter from '../common/SaleOrAfter.vue'

export default {

  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter },
  data() {
    // 这里存放数据
    return {
      editForm: {
        id: null,
        bigManagerId: null,
        areaName: null,
        oldManagerId: null
      },
      editFormRules: {
        bigManagerId: [
          { required: true, message: '请选择大区经理', trigger: 'blur' }
        ],
        areaName: [
          { required: true, message: '请输入大区名称', trigger: 'blur' }
        ]
      },
      bigManagerList: [],
      loading: false,
      areaList: [],
      roleId: null,
      editDialogVisible: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    closeEditDialog() {
      this.editDialogVisible = false
    },
    async updateArea() {
      this.editDialogVisible = false
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.put('/ma/area/update', this.editForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getAreaList()
            this.editDialogVisible = false
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    showEditDialog(data) {
      this.getBigManagerList()
      this.editForm.bigManagerId = data.userId
      this.editForm.oldManagerId = data.userId
      this.editForm.areaName = data.areaName
      this.editForm.id = data.id

      this.editDialogVisible = true
    },
    initRole(data) {
      this.roleId = data
      this.getAreaList()
    },
    async getBigManagerList() {
      const { data: res } = await this.$http.get('/ma/user/getAllBigManager', { params: { roleId: this.roleId } })
      this.bigManagerList = res.data
    },
    async getAreaList() {
      const { data: res } = await this.$http.get('/ma/area/list', { params: { roleId: this.roleId } })
      this.loading = false
      this.areaList = res.data
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAreaList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.el-table {
  margin-top: 10px;
}
</style>
