<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>邮件公共管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="emailRoleList" stripe border>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-popconfirm
              title="确定删除吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="deleteById(scope.row.id)"
            >
              <el-button slot="reference" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      title="权限新增"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="closeRoleDialog"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="addFormRules"
        label-width="100px"
      >
        <el-form-item label="角色" prop="role">
          <saleOrAfter
            ref="roleAddChild"
            @role-child="initAddRole"
          ></saleOrAfter>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input type="input" v-model="addForm.email"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addEmailRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter },
  data() {
    // 这里存放数据
    var checkRole = (rule, value, cb) => {
      if (!this.addForm.roleId) {
        cb(new Error('请选择角色'))
      }
      return cb()
    }
    return {
      addDialogVisible: false,
      emailRoleList: [],
      addForm: {
        roleId: null,
        email: null
      },
      addFormRules: {
        role: [{ validator: checkRole, trigger: 'blur' }],
        email: [
          { required: true, message: '请填写邮箱', trigger: 'blur' }
        ]
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    closeRoleDialog() {
      this.addDialogVisible = false
    },
    initAddRole(data) {
      this.addForm.roleId = data
    },
    initRole(data) {
      this.roleId = data
      this.getEmailRoleList()
    },
    async getEmailRoleList() {
      const { data: res } = await this.$http.get('/ma/email/role/list', { params: { roleId: this.roleId } })
      this.emailRoleList = res.data
    },
    async deleteById(id) {
      const { data: res } = await this.$http.delete(`/ma/email/role/${id}`)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.getEmailRoleList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async addEmailRole() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/ma/email/addEmailRole', this.addForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getEmailRoleList()
            this.addDialogVisible = false
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getEmailRoleList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
</style>
