<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>自检管理</el-breadcrumb-item>
      <el-breadcrumb-item>自检列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>

        <el-col :span="5.5">
          <el-date-picker
            v-model="beginEndDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getSelfCheckList"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '00:00:00']"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入查询关键字"
            width="20px"
            v-model="queryInfo.query.key"
            type="text"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getSelfCheckList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="openAddDialog">新增</el-button>
          <el-button type="info" @click="importDialogVisible = true"
            >导入</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="selfCheckList" stripe border>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column
          width="160px"
          label="开始时间"
          prop="beginDate"
        ></el-table-column>
        <el-table-column
          width="160px"
          label="截止时间"
          prop="endDate"
        ></el-table-column>
        <el-table-column
          width="500px"
          label="整改项"
          prop="content"
        ></el-table-column>
        <el-table-column label="类型" prop="typeName"></el-table-column>
        <el-table-column label="经销商" prop="dealerCode"></el-table-column>
        <el-table-column label="操作" width="360px">
          <template slot-scope="scope">
            <el-button type="primary" @click="openDetail(scope.row.id)"
              >查看</el-button
            >
            <el-popconfirm
              style="margin-left: 10px"
              title="确定投放下月吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="sendNextMonth(scope.row.id)"
            >
              <el-button slot="reference" type="info">投放</el-button>
            </el-popconfirm>
            <el-popconfirm
              style="margin-left: 10px"
              title="确定删除吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="deleteSelfCheck(scope.row.id)"
            >
              <el-button slot="reference" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      title="经销商自检数据"
      :visible.sync="detailDialogVisible"
      width="70%"
      @close="closeDetailDialog"
    >
      <el-row type="flex" :gutter="10">
        <el-col :span="5">
          <el-input v-model="key" placeholder="查询经销商"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="openDetail(selfCheckId)"
            >查询</el-button
          >
          <el-button @click="exportData(selfCheckId)" type="warning"
            >导出</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="detailList" stripe border v-loading="loading">
        <el-table-column label="经销商" prop="dealerCode"></el-table-column>
        <el-table-column label="整改内容" prop="comment"></el-table-column>
        <el-table-column label="当前状态" prop="state"></el-table-column>
        <el-table-column label="小区评论" prop="content"></el-table-column>
        <el-table-column label="整改计划" prop="plan"></el-table-column>
        <el-table-column label="整改时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="110px">
          <template slot-scope="scope">
            <el-popconfirm
              style="margin-left: 10px"
              title="确定重置吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="resetSelfCheck(scope.row)"
            >
              <el-button slot="reference" type="danger">重置</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      title="新增经销商自检"
      :visible.sync="addDialogVisible"
      width="50%"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="addFormRules"
        label-width="100px"
      >
        <el-form-item label="角色" prop="role">
          <saleOrAfter
            ref="roleAddChild"
            @role-child="initAddRole"
          ></saleOrAfter>
        </el-form-item>

        <el-form-item label="起止时间" prop="beginEndDate">
          <el-date-picker
            v-model="addForm.beginEndDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getSelfCheckList"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '00:00:00']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="自检内容" prop="content">
          <el-input type="textarea" v-model="addForm.content"></el-input>
        </el-form-item>
        <el-form-item label="自检类型" prop="typeId">
          <el-select
            v-model="addForm.typeId"
            placeholder="请选择类型"
            filterable
          >
            <el-option
              v-for="item in selfTypeList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图片">
          <el-switch v-model="addForm.isPicture"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSelfCheck">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="模板导入"
      :center="true"
      :visible.sync="importDialogVisible"
      width="30%"
    >
      <el-upload
        class="upload-demo"
        ref="upload"
        drag
        :file-list="fileList"
        :auto-upload="false"
        :before-upload="beforeUpload"
        :limit="1"
        action=""
        :on-exceed="handleExceed"
        :http-request="uploadSelfCheck"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-button type="text" @click="downLoadTempalte"
        >下载经销商自检模板</el-button
      >

      <span slot="footer" class="dialog-footer">
        <el-button @click="importDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          element-loading-text="系统下发中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-loading.fullscreen.lock="fullscreenLoading"
          @click="submitUpload"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue'
import { Api } from '@/assets/js/api.js'

export default {

  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter },
  data() {
    var checkRole = (rule, value, cb) => {
      if (!this.addForm.roleId) {
        cb(new Error('请选择角色'))
      }
      return cb()
    }
    // 这里存放数据
    return {
      fullscreenLoading: false,
      fileList: [],
      importDialogVisible: false,
      selfTypeList: [],
      addForm: {
        isPicture: false,
        roleId: null,
        content: null,
        typeId: null,
        beginDate: null,
        endDate: null,
        beginEndDate: null
      },
      addFormRules: {
        role: [{ validator: checkRole, trigger: 'blur' }],
        beginEndDate: [
          { required: true, message: '请选择起止时间', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请填写自检内容', trigger: 'blur' }
        ],
        typeId: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      addDialogVisible: false,
      loading: false,
      selfCheckId: null,
      key: null,
      detailList: [],
      detailDialogVisible: false,
      beginEndDate: null,
      yearList: [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      selfCheckList: [],
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          roleId: null,
          beginDate: null,
          key: null
        }
      },
      total: null
    }
  },
  methods: {
    downLoadTempalte() {
      const url = Api.apiDomain + '/ma/selfCheck/downloadTemplate'
      window.open(url, '_self')
    },
    beforeUpload(file) {
      // 判断文件格式
      const name = file.name.split('.').pop()
      if (name !== 'xlsx' && name !== 'xls') {
        this.$message.error('只能选择excel文件')
        return false
      }
    },
    // 上传文件个数超过定义的数量
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 个文件')
    },
    submitUpload() {
      this.$nextTick(() => {
        this.$refs.upload.submit()
      })
    },
    async uploadSelfCheck(f) {
      const param = new FormData()
      param.append('file', f.file)
      this.fullscreenLoading = true
      const { data: res } = await this.$http.post('ma/selfCheck/import', param)
      this.fullscreenLoading = false

      if (res.code !== 0) {
        this.$message.error(res.msg)
      } else {
        this.$message.success('导入成功')
        this.fileList = []
        this.importDialogVisible = false
        this.getSelfCheckList()
      }
    },
    async exportData(id) {
      // const oriUrl = window.location.origin + window.location.pathname
      const url = Api.apiDomain + `/ma/selfCheck/exportData?id=${id}`

      const { data: res } = await this.$http.get(url, this.addForm)
      console.log()
      if (res.code === -20048) {
        this.$message.warning(res.msg)
      } else {
        window.open(url, '_self')
      }
    },
    addSelfCheck() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          this.addForm.beginDate = this.addForm.beginEndDate[0]
          this.addForm.endDate = this.addForm.beginEndDate[1]
          const { data: res } = await this.$http.post('ma/selfCheck', this.addForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.addDialogVisible = false
            this.getSelfCheckList()
            this.$refs.addFormRef.resetFields()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    initAddRole(data) {
      this.addForm.roleId = data
    },
    openAddDialog() {
      this.addDialogVisible = true
      this.getSelfTypeList()
    },
    async getSelfTypeList() {
      const { data: res } = await this.$http.get('ma/selfCheck/typeList')
      this.selfTypeList = res.data
    },
    async deleteSelfCheck(id) {
      const { data: res } = await this.$http.delete(`ma/selfCheck/${id}`)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.getSelfCheckList()
      } else {
        this.$message.error(res.msg)
      }
    },
    initRole(data) {
      this.queryInfo.query.roleId = data
      this.getSelfCheckList()
    },
    async getSelfCheckList() {
      if (this.beginEndDate) {
        this.queryInfo.query.beginDate = this.beginEndDate[0]
        console.log(this.queryInfo.query.beginDate)
      } else {
        this.queryInfo.query.beginDate = null
      }
      const { data: res } = await this.$http.post('ma/selfCheck/list', this.queryInfo)
      this.selfCheckList = res.data.content
      this.total = res.data.totalElements
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getSelfCheckList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getSelfCheckList()
    },
    async openDetail(data) {
      this.detailDialogVisible = true
      this.loading = true
      this.selfCheckId = data
      const key = this.key === '' ? null : this.key
      const { data: res } = await this.$http.get(`ma/selfCheck/detailList/${data}/${key}`)
      this.detailList = res.data
      this.loading = false
    },
    async sendNextMonth(id) {
      const { data: res } = await this.$http.get(`ma/selfCheck/sendNextMonth/${id}`)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.getSelfCheckList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async resetSelfCheck(data) {
      const { data: res } = await this.$http.get('ma/selfCheck/reset', { params: { selfCheckId: data.selfCheckId, selfCheckDealerId: data.selfCheckDealerId, dealerCode: data.dealerCode } })
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.openDetail(data.selfCheckId)
      } else {
        this.$message.error(res.msg)
      }
    },
    closeDetailDialog() {
      this.detailDialogVisible = false
      this.key = null
      this.selfCheckId = null
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getSelfCheckList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.el-table {
  margin-top: 10px;
}
/deep/ .el-upload {
  width: 100%;
  .el-upload-dragger {
    width: 100%;
  }
}
</style>
