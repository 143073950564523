<!--  -->
<template>
  <el-container class="home-container">
    <el-header height="90px">
      <div>
        <img src="../assets/logo.png" alt="" />
        <span>Volvo DSS 运维系统</span>
      </div>
      <el-button @click="logout" type="info">退出</el-button>
    </el-header>
    <el-container style="height: calc(100% - 90px);">
      <el-aside :width="!isCollapse ? '240px' : '64px'" style="height: 100%;">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <el-menu
          :default-active="activePath"
          :router="true"
          :collapse-transition="false"
          :unique-opened="true"
          :collapse="isCollapse"
          background-color="#2b4b6b"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <!--一级菜单 -->
          <el-submenu :index="item.path" v-for="item in menuList" :key="item.id">
            <!--一级菜单的模板区域 -->
            <template slot="title">
              <!--图标 -->
              <i :class="item.icon"></i>
              <!--文本 -->
              <span>{{ item.name }}</span>
            </template>
            <!--二级菜单 -->
            <el-menu-item :index="sub.path" v-for="sub in item.children" :key="sub.id" @click="saveNavState(sub.path)">
              <!--二级菜单的模板区域 -->
              <template slot="title">
                <!--图标 -->
                <i :class="item.icon"></i>
                <!--文本 -->
                <span>{{ sub.name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <!-- 路由占位符-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      isCollapse: false,
      menuList: [],
      activePath: "",
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 点击按钮切换菜单展开和折叠
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    async getMenuList() {
      const { data: res } = await this.$http.get("/ma/base/getMenuList");
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.menuList = res.data;
      this.activePath = window.sessionStorage.getItem("activePath");
    },
    saveNavState(path) {
      window.sessionStorage.setItem("activePath", path);
      this.activePath = path;
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getMenuList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less" scoped>
.home-container {
  height: 100%;
}
.el-header {
  background-color: #2b4b6b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 25px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 20px;
    }
  }
}
.el-aside {
  background-color: #2b4b6b;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #eaedf1;
}
.toggle-button {
  background-color: #0e4277;
  font-size: 15px;
  line-height: 24px;
  color: #909399;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
