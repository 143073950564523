<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>总部事项管理</el-breadcrumb-item>
      <el-breadcrumb-item>总部事项列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <el-select v-model="queryInfo.query.year" placeholder="请选择年份" @change="selectYear">
            <el-option v-for="item in yearList" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select v-model="queryInfo.query.month" placeholder="请选择月份" @change="getHeadMatterList">
            <el-option v-for="item in monthList" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-button type="success" @click="addRecord">下发总部事项</el-button>
          <!-- <el-button type="info" @click="exportDialogVisible = true"> 导入专项整改</el-button> -->
        </el-col>
      </el-row>
      <el-table :data="headMatterContentList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="整改年份" prop="year"></el-table-column>
        <el-table-column label="整改月份" prop="month"></el-table-column>
        <el-table-column width="200px" label="截止时间" prop="deadline"></el-table-column>
        <el-table-column width="300px" label="整改标题" prop="title"></el-table-column>
        <el-table-column width="300px" label="整改内容" prop="content"> </el-table-column>
        <el-table-column label="问卷名称" prop="questionName" show-overflow-tooltip> </el-table-column>
        <el-table-column label="问卷填写人数">
          <template slot-scope="scope">
            <el-tooltip effect="dark" placement="top">
              <div slot="content">填写人数：{{ scope.row.nameCnCount }}<br />发放人数：{{ scope.row.answerCount }}</div>
              <div>
                <span style="color: #409eff;">{{ scope.row.nameCnCount }}</span
                >/<span>{{ scope.row.answerCount }}</span>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="经销商" prop="dealerCodes"></el-table-column>
        <el-table-column label="小区经理" prop="nameCns"></el-table-column>
        <el-table-column label="市场经理" prop="marketManager"></el-table-column>
        <el-table-column label="导出数据" width="150px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-download" @click="exportData(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog :close-on-click-modal="false" title="模板导入" :center="true" :visible.sync="exportDialogVisible" width="30%">
      <el-upload
        class="upload-demo"
        ref="upload"
        drag
        :file-list="fileList"
        :auto-upload="false"
        :before-upload="beforeUpload"
        :limit="1"
        action=""
        :on-exceed="handleExceed"
        :http-request="uploadSpecial"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-button type="text" @click="downLoadTempalte">下载导入总部事项模板</el-button>

      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          element-loading-text="系统下发中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-loading.fullscreen.lock="fullscreenLoading"
          @click="submitUpload"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="下发总部事项" :visible.sync="addDialogVisible" width="50%" @close="closeAddDialog">
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="100px">
        <el-form-item label="角色" prop="role">
          <saleOrAfter ref="roleAddChild" @role-child="initAddRole"></saleOrAfter>
        </el-form-item>
        <el-form-item label="整改人" prop="dealerOrSmall">
          <el-tabs @tab-click="handleClick">
            <el-tab-pane label="经销商" name="first">
              <el-cascader v-model="addForm.checkDealers" :options="dealerCodes" :props="{ multiple: true }" clearable collapse-tags filterable></el-cascader>
            </el-tab-pane>
            <el-tab-pane label="小区经理" name="second">
              <el-cascader
                v-model="addForm.checkSmalls"
                :options="smallManagerList"
                :props="{ multiple: true }"
                clearable
                collapse-tags
                filterable
              ></el-cascader>
            </el-tab-pane>
            <el-tab-pane label="市场经理" name="three">
              <el-cascader
                v-model="addForm.checkMarkets"
                :options="marketManagerList"
                :props="{ multiple: true }"
                clearable
                collapse-tags
                filterable
              ></el-cascader>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="下发人" prop="handlerId">
          <el-select v-model="addForm.handlerId" placeholder="请选择下发人" filterable>
            <el-option v-for="item in haedUserList" :key="item.id" :label="item.nameCn" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="年月" prop="year">-->
        <!--          <el-date-picker-->
        <!--            v-model="addForm.year"-->
        <!--            type="year"-->
        <!--            value-format="yyyy"-->
        <!--            placeholder="选择年"-->
        <!--          >-->
        <!--          </el-date-picker>-->
        <!--          <el-date-picker-->
        <!--            v-model="addForm.month"-->
        <!--            type="month"-->
        <!--            value-format="M"-->
        <!--            placeholder="选择月"-->
        <!--          >-->
        <!--          </el-date-picker>-->
        <!--        </el-form-item>-->
        <el-form-item label="截止时间" prop="deadline">
          <el-date-picker v-model="addForm.deadline" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" default-time="23:59:59">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="整改类型" prop="typeId">
          <el-select v-model="addForm.typeId" placeholder="选择整改类型" filterable>
            <el-option v-for="item in specialTypeList" :key="item.id" :label="item.typeName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="整改标题" prop="title">
          <el-input type="textarea" v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="示例图片">
          <el-upload
            class="upload-demo"
            :on-remove="handleRemove"
            :before-upload="beforeUploadImg"
            :http-request="uploadImg"
            :file-list="addForm.imgList"
            action=""
            list-type="picture"
          >
            <el-button size="small" type="primary" style="float: left;">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="小区经理审核">
          <el-switch v-model="addForm.isSmall"></el-switch>
        </el-form-item>
        <el-form-item label="整改内容" prop="content">
          <el-input type="textarea" v-model="addForm.content"></el-input>
        </el-form-item>
        <el-form-item label="关联问卷" prop="answerId">
          <el-select v-model="addForm.answerId" placeholder="请选择关联问卷" filterable>
            <el-option v-for="item in questionnaireList" :key="item.id" :label="item.questionName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addHeadMatter">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SaleOrAfter from "../common/SaleOrAfter.vue";
import { Api } from "@/assets/js/api.js";

function initQuery() {
  return {
    year: null,
    month: null,
    roleId: null,
  };
}

export default {
  components: { SaleOrAfter },
  data() {
    var checkDealerOrSmall = (rule, value, cb) => {
      if (this.addForm.checkSmalls.length === 0 && this.addForm.checkDealers.length === 0 && this.addForm.checkMarkets.length === 0) {
        cb(new Error("请选择整改人"));
      }
      return cb();
    };
    var checkRole = (rule, value, cb) => {
      if (!this.addForm.roleId) {
        cb(new Error("请选择角色"));
      }
      return cb();
    };
    // 这里存放数据
    return {
      fullscreenLoading: false,
      fileList: [],
      exportDialogVisible: false,
      haedUserList: [],
      specialTypeList: [],
      smallManagerList: [],
      marketManagerList: [],
      dealerCodes: [],
      addForm: {
        imgList: [],
        roleId: null,
        checkSmalls: [],
        checkDealers: [],
        checkMarkets: [],
        handlerId: null,
        typeId: null,
        deadline: null,
        content: null,
        title: null,
        isSmall: null,
      },
      addFormRules: {
        year: [{ required: true, message: "请选择年月", trigger: "blur" }],
        content: [{ required: true, message: "请填写整改内容", trigger: "blur" }],
        target: [{ required: true, message: "请填写整改目标", trigger: "blur" }],
        deadline: [{ required: true, message: "请选择截止时间", trigger: "blur" }],
        handlerId: [{ required: true, message: "请选择下发人", trigger: "blur" }],
        typeId: [{ required: true, message: "请选择整改类型", trigger: "blur" }],
        role: [{ validator: checkRole, trigger: "blur" }],
        dealerOrSmall: [{ validator: checkDealerOrSmall, trigger: "blur" }],
      },
      addDialogVisible: false,
      loading: false,
      yearList: [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      headMatterContentList: [],
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: initQuery(),
      },
      total: null,
      questionnaireList: [],
    };
  },
  created() {
    this.getHeadMatterList(true);
    this.getTypeList();
    this.getHeadUserList();
  },
  methods: {
    addRecord() {
      this.addDialogVisible = true;

      this.getQuestionnaireList();
    },
    async uploadSpecial(f) {
      const param = new FormData();
      param.append("file", f.file);
      this.fullscreenLoading = true;
      const { data: res } = await this.$http.post("ma/special/import", param);
      this.fullscreenLoading = false;

      if (res.code !== 0) {
        this.$message.error(res.msg);
      } else {
        this.$message.success("导入成功");
        this.fileList = [];
        this.exportDialogVisible = false;
        this.getHeadMatterList(true);
      }
    },
    handleRemove(file, fileList) {
      this.addForm.imgList = this.addForm.imgList.filter((item) => item !== file);
    },
    async uploadImg(f) {
      const param = new FormData();
      param.append("file", f.file);

      const { data: res } = await this.$http.post("/ma/base/uploadImg", param);
      res.data.url = Api.ossDomain + res.data.url;
      this.addForm.imgList.push(res.data);
    },
    submitUpload() {
      this.$nextTick(() => {
        this.$refs.upload.submit();
      });
    },
    beforeUpload(file) {
      // 判断文件格式
      const name = file.name.split(".").pop();
      if (name !== "xlsx" && name !== "xls") {
        this.$message.error("只能选择excel文件");
        return false;
      }
    },
    // 上传文件个数超过定义的数量
    handleExceed(files, fileList) {
      this.$message.warning("当前限制选择 1 个文件");
    },
    beforeUploadImg(file) {
      // 判断文件格式
      const name = file.name.split(".").pop();
      if (name !== "png" && name !== "jpg") {
        this.$message.error("只能选择jpg或者png文件");
        return false;
      }
    },
    downLoadTempalte() {
      const url = Api.apiDomain + "/ma/special/downloadTemplate";
      window.open(url, "_self");
    },
    async addHeadMatter() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post("/ma/headMatter/addHeadMatter", this.addForm);
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.addForm.imgList = [];
            this.$refs.addFormRef.resetFields();
            this.addForm.checkDealers = [];
            this.addForm.checkSmalls = [];
            this.addForm.month = null;
            this.addDialogVisible = false;
            this.getHeadMatterList(true);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    handleClick() {
      this.addForm.checkDealers = [];
      this.addForm.checkSmalls = [];
      if (!this.addForm.roleId) {
        this.$message.info("请选择角色");
      } else {
        this.getDealerCodes();
        this.getSmallManagerList();
        this.getMarketManagerList();
      }
    },
    initAddRole(data) {
      this.addForm.roleId = data;
      this.getDealerCodes();
      this.getSmallManagerList();
      this.getMarketManagerList();
    },
    async getSmallManagerList() {
      const { data: res } = await this.$http.get("/ma/user/getSmallCascader", { params: { roleId: this.addForm.roleId } });
      this.smallManagerList = res.data;
    },
    async getMarketManagerList() {
      const { data: res } = await this.$http.get("/ma/user/getMarketCascader", { params: { roleId: this.addForm.roleId } });
      this.marketManagerList = res.data;
    },
    async getHeadUserList() {
      const { data: res } = await this.$http.get("/ma/user/headListForHead");
      this.haedUserList = res.data;
    },
    async getTypeList() {
      const { data: res } = await this.$http.get("/ma/headMatter/type/list");
      this.specialTypeList = res.data;
    },
    async getDealerCodes() {
      const { data: res } = await this.$http.get("/ma/base/bigSmallDealerList", { params: { roleId: this.addForm.roleId } });
      this.dealerCodes = res.data;
    },
    closeAddDialog() {},
    async exportData(data) {
      const id = data.id;
      // const oriUrl = window.location.origin + window.location.pathname
      const url = Api.apiDomain + `/ma/headMatter/exportData?id=${id}`;
      const { data: res } = await this.$http.get(url, this.addForm);
      if (res.code === -20048) {
        this.$message.error(res.msg);
      } else {
        window.open(url, "_self");
      }
    },
    selectYear() {
      this.queryInfo.query.month = null;
      this.getHeadMatterList(true);
    },
    initRole(data) {
      this.queryInfo.query.roleId = data;
      this.getHeadMatterList(true);
    },
    async getHeadMatterList(refresh = false) {
      if (refresh) {
        this.queryInfo.pageNum = 1;
      }

      this.loading = true;
      const { data: res } = await this.$http.post("/ma/headMatter/content/list", this.queryInfo);
      this.loading = false;

      this.headMatterContentList = res.data.content;
      this.total = res.data.totalElements;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getHeadMatterList(true);
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getHeadMatterList();
    },
    async getQuestionnaireList() {
      const { data: res } = await this.$http.get("questionnaire/findAll");
      this.questionnaireList = res.data;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-table td.el-table__cell div {
  height: 100px;
  overflow: overlay;
}

.el-table {
  margin-top: 10px;
}

/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
  }
}
</style>
