<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>专项整改管理</el-breadcrumb-item>
      <el-breadcrumb-item>专项整改列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2.5">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.year"
            placeholder="年份"
            @change="selectYear"
          >
            <el-option
              v-for="item in yearList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.month"
            placeholder="月份"
            @change="getSpecialContentList"
          >
            <el-option
              v-for="item in monthList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.publisherId"
            placeholder="发布人"
            @change="getSpecialContentList"
            filterable
          >
            <el-option
              v-for="item in publisher"
              :key="item.publisherId"
              :label="item.publisherName"
              :value="item.publisherId"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2.1">
          <el-select
            v-model="queryInfo.query.smallAreaId"
            placeholder="小区经理"
            @change="getSpecialContentList"
            filterable
            @click.native="handleClick1"
          >
            <el-option
              v-for="item in smallAreaList"
              :key="item.id"
              :label="item.smallAreaName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <dealer
            ref="dealerChild"
            @dealer-child="initQueryInfoForDealer"
          ></dealer>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.state"
            placeholder="状态"
            @change="getSpecialContentList"
            filterable
          >
            <el-option
              v-for="item in stateList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.type"
            placeholder="类型"
            @change="getSpecialContentList"
            filterable
          >
            <el-option
              v-for="item in specialTypeList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4.6">
          <div class="block" >
            <span class="demonstration"></span>
            <el-date-picker
              v-model="queryInfo.query.date"
              type="date"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="23:59:59"
              style="width: 100%"
              @change="getSpecialContentList"
              placeholder="截止时间">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="4.6">
          <div class="block">
            <span class="demonstration"></span>
            <el-date-picker
              v-model="queryInfo.query.createDate"
              type="date"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="23:59:59"
              style="width: 100%"
              @change="getSpecialContentList"
              placeholder="创建时间">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="6">
          <el-button type="success" @click="addDialogVisible = true"
          >下发专项整改</el-button
          >
          <el-button type="info" @click="exportDialogVisible = true"
          >导入专项整改</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="exportData">导出数据
          </el-button
          >
        </el-col>
      </el-row>
      <el-table :data="specialContentList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="整改年份" prop="year"></el-table-column>
        <el-table-column label="整改月份" prop="month"></el-table-column>
        <el-table-column
          width="140px"
          label="创建时间"
          prop="createTime"
        ></el-table-column>
        <el-table-column
          width="160px"
          label="截止时间"
          prop="deadline"
        ></el-table-column>
        <el-table-column
          width="230px"
          label="整改项"
          prop="content"
        ></el-table-column>
        <el-table-column
          width="230px"
          label="整改目标"
          prop="target"
        ></el-table-column>
        <el-table-column label="发布人" prop="publisherName"></el-table-column>
        <el-table-column label="经销商" prop="dealerCodes"></el-table-column>
        <el-table-column label="小区经理" prop="smallManagerName"></el-table-column>
        <el-table-column label="状态" prop="state"></el-table-column>
        <el-table-column label="图片" width="180px">
          <template slot-scope="scope">
            <el-image style="width: 150px; height: 90px"
                      :src="scope.row.url[0]"
                      :preview-src-list="scope.row.url">
              <div slot="error" class="image-slot" style="width: auto;height: auto">
                <i class="el-icon-picture-outline">暂无图片</i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="openDetail(scope.row.id)"
            >查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :close-on-click-modal="false"
      title="模板导入"
      :center="true"
      :visible.sync="exportDialogVisible"
      width="30%"
    >
      <el-upload
        class="upload-demo"
        ref="upload"
        drag
        :file-list="fileList"
        :auto-upload="false"
        :before-upload="beforeUpload"
        :limit="1"
        action=""
        :on-exceed="handleExceed"
        :http-request="uploadSpecial"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-button type="text" @click="downLoadTempalte"
      >下载导入专项整改模板</el-button
      >

      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          element-loading-text="系统下发中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-loading.fullscreen.lock="fullscreenLoading"
          @click="submitUpload"
        >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="下发专项整改"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="closeAddDialog"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="addFormRules"
        label-width="100px"
      >
        <el-form-item label="角色" prop="role">
          <saleOrAfter
            ref="roleAddChild"
            @role-child="initAddRole"
          ></saleOrAfter>
        </el-form-item>
        <el-form-item label="整改人" prop="dealerOrSmall">
          <el-tabs @tab-click="handleClick">
            <el-tab-pane label="经销商" name="first">
              <el-cascader
                v-model="addForm.checkDealers"
                :options="dealerCodes"
                :props="{ multiple: true }"
                clearable
                collapse-tags
                filterable
              ></el-cascader>
            </el-tab-pane>
            <el-tab-pane label="小区经理" name="second">
              <el-cascader
                v-model="addForm.checkSmalls"
                :options="smallManagerList"
                :props="{ multiple: true }"
                clearable
                collapse-tags
                filterable
              ></el-cascader>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="下发人" prop="handlerId">
          <el-select
            v-model="addForm.handlerId"
            placeholder="请选择下发人"
            filterable
          >
            <el-option
              v-for="item in haedUserList"
              :key="item.id"
              :label="item.nameCn"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年月" prop="year">
          <el-date-picker
            v-model="addForm.year"
            type="year"
            value-format="yyyy"
            placeholder="选择年"
          >
          </el-date-picker>
          <el-date-picker
            v-model="addForm.month"
            type="month"
            value-format="M"
            placeholder="选择月"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="截止时间" prop="deadline">
          <el-date-picker
            v-model="addForm.deadline"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
            default-time="23:59:59"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="整改类型" prop="typeId">
          <el-select
            v-model="addForm.typeId"
            placeholder="选择整改类型"
            filterable
          >
            <el-option
              v-for="item in specialTypeList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="整改内容" prop="content">
          <el-input type="textarea" v-model="addForm.content"></el-input>
        </el-form-item>
        <el-form-item label="示例图片">
          <el-upload
            class="upload-demo"
            :on-remove="handleRemove"
            :before-upload="beforeUploadImg"
            :http-request="uploadImg"
            :file-list="addForm.imgList"
            action=""
            list-type="picture"
          >
            <el-button size="small" type="primary" style="float: left"
            >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="整改目标" prop="target">
          <el-input type="textarea" v-model="addForm.target"></el-input>
        </el-form-item>
        <el-form-item label="核检类型" prop="checkType">
          <el-input v-model="addForm.checkType"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSpecial">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue'
import { Api } from '@/assets/js/api.js'
import Dealer from '../common/Dealer.vue'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter, Dealer },
  data() {
    var checkDealerOrSmall = (rule, value, cb) => {
      if (this.addForm.checkSmalls.length === 0 && this.addForm.checkDealers.length === 0) {
        cb(new Error('请选择整改人'))
      }
      return cb()
    }
    var checkRole = (rule, value, cb) => {
      if (!this.addForm.roleId) {
        cb(new Error('请选择角色'))
      }
      return cb()
    }
    // 这里存放数据
    return {
      fullscreenLoading: false,
      fileList: [],
      exportDialogVisible: false,
      haedUserList: [],
      specialTypeList: [],
      smallManagerList: [],
      smallAreaList: [],
      dealerCodes: [],
      stateList: [],
      addForm: {
        imgList: [],
        year: null,
        month: null,
        roleId: null,
        checkSmalls: [],
        checkDealers: [],
        handlerId: null,
        typeId: null,
        deadline: null,
        content: null,
        target: null,
        checkType: null,
        publisher: null
      },
      addFormRules: {
        year: [
          { required: true, message: '请选择年月', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请填写整改内容', trigger: 'blur' }
        ],
        target: [
          { required: true, message: '请填写整改目标', trigger: 'blur' }
        ],
        deadline: [
          { required: true, message: '请选择截止时间', trigger: 'blur' }
        ],
        handlerId: [
          { required: true, message: '请选择下发人', trigger: 'blur' }
        ],
        typeId: [
          { required: true, message: '请选择整改类型', trigger: 'blur' }
        ],
        role: [{ validator: checkRole, trigger: 'blur' }],
        dealerOrSmall: [{ validator: checkDealerOrSmall, trigger: 'blur' }]

      },
      addDialogVisible: false,
      loading: false,
      yearList: [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      specialContentList: [],
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          year: null,
          month: null,
          roleId: null,
          publisherId: null,
          date: null,
          createDate: null,
          type: null,
          state: null,
          dealerCodes: null,
          smallAreaId: null
        }
      },
      total: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async uploadSpecial(f) {
      const param = new FormData()
      param.append('file', f.file)
      this.fullscreenLoading = true
      const { data: res } = await this.$http.post('ma/special/import', param)
      this.fullscreenLoading = false

      if (res.code !== 0) {
        this.$message.error(res.msg)
      } else {
        this.$message.success('导入成功')
        this.fileList = []
        this.exportDialogVisible = false
        this.getSpecialContentList()
      }
    },
    handleRemove(file, fileList) {
      this.addForm.imgList = this.addForm.imgList.filter(item => item !== file)
    },
    async uploadImg(f) {
      const param = new FormData()
      param.append('file', f.file)

      const { data: res } = await this.$http.post('/ma/base/uploadImg', param)
      res.data.url = Api.ossDomain + res.data.url
      this.addForm.imgList.push(res.data)
    },
    submitUpload() {
      this.$nextTick(() => {
        this.$refs.upload.submit()
      })
    },
    beforeUpload(file) {
      // 判断文件格式
      const name = file.name.split('.').pop()
      if (name !== 'xlsx' && name !== 'xls') {
        this.$message.error('只能选择excel文件')
        return false
      }
    },
    // 上传文件个数超过定义的数量
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 个文件')
    },
    beforeUploadImg(file) {
      // 判断文件格式
      const name = file.name.split('.').pop()
      if (name !== 'png' && name !== 'jpg') {
        this.$message.error('只能选择jpg或者png文件')
        return false
      }
    },
    downLoadTempalte() {
      const url = Api.apiDomain + '/ma/special/downloadTemplate'
      window.open(url, '_self')
    },
    async addSpecial() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/ma/special/addSpecial', this.addForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.addForm.imgList = []
            this.$refs.addFormRef.resetFields()
            this.addForm.checkDealers = []
            this.addForm.checkSmalls = []
            this.addForm.month = null
            this.addDialogVisible = false
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    handleClick1() {
      if (!this.queryInfo.query.roleId) {
        this.$message.info('请选择角色')
      } else {
        this.getSmallAreaList()
      }
    },
    handleClick() {
      this.addForm.checkDealers = []
      this.addForm.checkSmalls = []
      if (!this.addForm.roleId) {
        this.$message.info('请选择角色')
      } else {
        this.getDealerCodes()
        this.getSmallManagerList()
      }
    },
    initQueryInfoForDealer(data) {
      this.queryInfo.query.dealerCode = data
    },
    initAddRole(data) {
      this.addForm.roleId = data
      this.getDealerCodes()
      this.getSmallManagerList()
    },
    async getSmallManagerList() {
      const { data: res } = await this.$http.get('/ma/user/getSmallCascader', { params: { roleId: this.addForm.roleId } })
      this.smallManagerList = res.data
    },
    async getHeadUserList() {
      const { data: res } = await this.$http.get('/ma/user/headList')
      this.haedUserList = res.data
    },
    async getSpecialType() {
      const { data: res } = await this.$http.get('/ma/special/getSpecialType')
      this.specialTypeList = res.data
    },
    async getDealerCodes() {
      const { data: res } = await this.$http.get('/ma/base/bigSmallDealerList', { params: { roleId: this.addForm.roleId } })
      this.dealerCodes = res.data
    },
    closeAddDialog() {
    },
    async getPublisherName() {
      const { data: res } = await this.$http.get('/ma/special/getPublisherName')
      this.publisher = res.data
    },
    async getSmallAreaList() {
      const { data: res } = await this.$http.get('/ma/base/getSmallAreaList', { params: { roleId: this.queryInfo.query.roleId } })
      this.smallAreaList = res.data
    },
    async getState() {
      const { data: res } = await this.$http.get('/ma/special/getState')
      this.stateList = res.data
    },
    async exportData(data) {
      const id = data.id
      // const oriUrl = window.location.origin + window.location.pathname
      const url = Api.apiDomain + `/ma/special/exportData?id=${id}`
      const { data: res } = await this.$http.get(url, this.addForm)
      if (res.code === -20048) {
        this.$message.error(res.msg)
      } else {
        window.open(url, '_self')
      }
    },
    selectYear() {
      this.queryInfo.query.month = null
      this.getSpecialContentList()
    },
    initRole(data) {
      this.queryInfo.query.roleId = data
      this.getSpecialContentList()
    },
    async getSpecialContentList() {
      this.loading = true
      const { data: res } = await this.$http.post('/ma/special/content/list', this.queryInfo)
      this.specialContentList = res.data.content
      this.total = res.data.totalElements
      this.loading = false
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getSpecialContentList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getSpecialContentList()
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getSpecialContentList()
    this.getSpecialType()
    this.getState()
    this.getHeadUserList()
    this.getPublisherName()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
/deep/ .el-table td.el-table__cell div {
  height: 100px;
  overflow: overlay;
}
.el-table {
  margin-top: 10px;
}
/deep/ .el-upload {
  width: 100%;
  .el-upload-dragger {
    width: 100%;
  }
}
</style>
