<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>经销商管理</el-breadcrumb-item>
      <el-breadcrumb-item>经销商列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <dealer ref="dealerChild" @dealer-child="initDealerCode"></dealer>
        </el-col>
        <el-col :span="2">
          <el-select v-model="queryInfo.query.state" placeholder="请选择状态">
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input
            placeholder="请输入查询关键字"
            width="20px"
            v-model="queryInfo.query.key"
            type="text"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="info" @click="clearCondition">清空条件</el-button>
          <el-button type="primary" @click="addDialogVisible = true"
            >添加经销商</el-button
          >
          <el-button type="primary" @click="exportDialogVisible = true"
            >导出名单</el-button
          >
        </el-col>
      </el-row>

      <el-table :data="dealerList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="大区" prop="areaName"></el-table-column>
        <el-table-column label="code" prop="dealerCode"></el-table-column>
        <el-table-column
          label="简称"
          width="130px"
          prop="dealerSimpleName"
        ></el-table-column>
        <el-table-column
          label="全称"
          width="350px"
          prop="dealerFullName"
        ></el-table-column>
        <el-table-column label="集团" prop="dealerGroup"></el-table-column>
        <el-table-column label="省" prop="provinces"></el-table-column>
        <el-table-column label="市" prop="city"></el-table-column>
        <el-table-column
          width="100px"
          label="经度"
          prop="lng"
        ></el-table-column>
        <el-table-column
          width="100px"
          label="纬度"
          prop="lat"
        ></el-table-column>
        <el-table-column
          width="180px"
          label="创建时间"
          prop="createTime"
        ></el-table-column>
        <el-table-column
          label="开店状态"
          width="100px"
          prop="state"
        ></el-table-column>
        <el-table-column label="状态" prop="isDelete">
          <template slot-scope="scope">
            <el-switch
              @change="updateStatus(scope.row)"
              :active-value="false"
              :inactive-value="true"
              v-model="scope.row.isDelete"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
            ></el-button>
            <el-popconfirm
              style="margin-left: 10px"
              title="确定永久删除吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="deleteById(scope.row.id)"
            >
              <el-button
                slot="reference"
                type="danger"
                icon="el-icon-delete"
                size="mini"
              ></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[5, 10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 修改经销商 -->
    <el-dialog
      :close-on-click-modal="false"
      title="修改经销商"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="closeEditDialog"
    >
      <el-form
        ref="editFormRef"
        :model="editForm"
        :rules="editFormRules"
        label-width="120px"
      >
        <el-form-item label="大区" prop="areaId">
          <bigArea
            ref="dealerEditRef"
            :areaId="editForm.areaId"
            @big-child="editDealerArea"
          >
          </bigArea>
        </el-form-item>
        <el-form-item label="经销商code" prop="dealerCode">
          <el-input v-model="editForm.dealerCode" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="经销商简称" prop="dealerSimpleName">
          <el-input v-model="editForm.dealerSimpleName"></el-input>
        </el-form-item>
        <el-form-item label="经销商全称" prop="dealerFullName">
          <el-input v-model="editForm.dealerFullName"></el-input>
        </el-form-item>
        <el-form-item label="集团" prop="delearGroup">
          <el-input v-model="editForm.delearGroup"></el-input>
        </el-form-item>
        <el-form-item label="省" prop="provinces">
          <el-input v-model="editForm.provinces"></el-input>
        </el-form-item>
        <el-form-item label="市" prop="city">
          <el-input v-model="editForm.city"></el-input>
        </el-form-item>
        <el-form-item label="经度" prop="lng">
          <el-input v-model="editForm.lng"></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="lat">
          <el-input v-model="editForm.lat"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateDealer">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加经销商-->
    <el-dialog
      :close-on-click-modal="false"
      title="添加经销商"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="closeAddDialog"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="editFormRules"
        label-width="100px"
      >
        <el-form-item label="大区" prop="areaId">
          <bigArea
            ref="dealerAddRef"
            :areaId="addForm.areaId"
            @big-child="addDealerArea"
          >
          </bigArea>
        </el-form-item>
        <el-form-item label="经销商code" prop="dealerCode">
          <el-input v-model="addForm.dealerCode"></el-input>
        </el-form-item>
        <el-form-item label="经销商简称" prop="dealerSimpleName">
          <el-input v-model="addForm.dealerSimpleName"></el-input>
        </el-form-item>
        <el-form-item label="经销商全称" prop="dealerFullName">
          <el-input v-model="addForm.dealerFullName"></el-input>
        </el-form-item>
        <el-form-item label="集团" prop="delearGroup">
          <el-input v-model="addForm.delearGroup"></el-input>
        </el-form-item>
        <el-form-item label="省" prop="provinces">
          <el-input v-model="addForm.provinces"></el-input>
        </el-form-item>
        <el-form-item label="市" prop="city">
          <el-input v-model="addForm.city"></el-input>
        </el-form-item>
        <el-form-item label="经度" prop="lng">
          <el-input v-model="addForm.lng"></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="lat">
          <el-input v-model="addForm.lat"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDealer">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="导出经销商名单"
      :visible.sync="exportDialogVisible"
      center
      width="50%"
    >
      <el-form
        ref="exportFormRef"
        :model="exportForm"
        :rules="exportFormRules"
        label-width="100px"
      >
        <el-form-item label="角色" prop="roleId">
          <saleOrAfter
            ref="roleAddChild"
            @role-child="initExportRole"
          ></saleOrAfter>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportData">导 出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SaleOrAfter from '../common/SaleOrAfter.vue'
import Dealer from '../common/Dealer.vue'
import BigArea from '../common/BigArea.vue'
import { Api } from '@/assets/js/api.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { Dealer, BigArea, SaleOrAfter },
  data() {
    // 这里存放数据
    return {
      exportDialogVisible: false,
      exportRoleId: null,
      addForm: {
        areaId: null,
        dealerCode: null,
        dealerSimpleName: null,
        dealerFullName: null,
        delearGroup: null,
        provinces: null,
        city: null,
        lng: null,
        lat: null
      },
      editForm: {
        id: null,
        areaId: null,
        dealerCode: null,
        dealerSimpleName: null,
        dealerFullName: null,
        delearGroup: null,
        provinces: null,
        city: null,
        lng: null,
        lat: null
      },
      editFormRules: {
        dealerCode: [
          { required: true, message: '请输入经销商code', trigger: 'blur' }
        ],
        dealerSimpleName: [
          { required: true, message: '请输入经销商简称', trigger: 'blur' }
        ],
        dealerFullName: [
          { required: true, message: '请输入经销商全称', trigger: 'blur' }
        ],
        delearGroup: [
          { required: true, message: '请输入集团', trigger: 'blur' }
        ],
        provinces: [
          { required: true, message: '请输入省', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请输入市', trigger: 'blur' }
        ],
        lng: [
          { required: true, message: '请输入经度', trigger: 'blur' }
        ],
        lat: [
          { required: true, message: '请输入纬度', trigger: 'blur' }
        ]
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          state: null,
          dealerCode: null,
          key: null
        }
      },
      stateList: [{
        label: 'OPENING',
        value: 'OPENING'
      }, {
        label: 'CLOSE',
        value: 'CLOSE'
      }, {
        label: 'EXAMINE',
        value: 'EXAMINE'
      }],
      total: 0,
      dealerList: [],
      loading: false,
      editDialogVisible: false,
      addDialogVisible: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    initExportRole(data) {
      this.exportRoleId = data
    },
    addDealer() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/ma/dealer/add', this.addForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getDealerList()
            this.addDialogVisible = false
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    exportData() {
      if (!this.exportRoleId) {
        this.$message.warning('请选择角色')
      } else {
        const url = Api.apiDomain + '/ma/dealer/exportData/' + this.exportRoleId
        window.open(url, '_self')
      }
    },
    closeEditDialog() {
      this.$refs.editFormRef.resetFields()
    },
    closeAddDialog() {
      this.$refs.addFormRef.resetFields()
    },
    async updateStatus(dealer) {
      const { data: res } = await this.$http.put(`/ma/dealer/${dealer.id}/state/${dealer.isDelete}`)
      if (res.code === 0) {
        return this.$message.success(res.msg)
      } else {
        dealer.state = !dealer.state
        return this.$message.error(res.msg)
      }
    },
    // pageSize改变
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.loading = true
      this.getDealerList()
    },
    // pageNum 改变
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getDealerList()
    },
    async getDealerList() {
      const { data: res } = await this.$http.post('/ma/dealer/list', this.queryInfo)
      this.loading = false
      this.dealerList = res.data.content
      this.total = res.data.totalElements
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get(`/ma/dealer/${id}`)
      this.editForm.id = res.data.id
      this.editForm.dealerCode = res.data.dealerCode
      this.editForm.areaId = res.data.areaId
      this.editForm.dealerSimpleName = res.data.dealerSimpleName
      this.editForm.dealerFullName = res.data.dealerFullName
      this.editForm.delearGroup = res.data.delearGroup
      this.editForm.provinces = res.data.provinces
      this.editForm.city = res.data.city
      this.editForm.lng = res.data.lng
      this.editForm.lat = res.data.lat
      this.editDialogVisible = true
    },
    async deleteById(id) {
      const { data: res } = await this.$http.delete(`/ma/dealer/${id}`)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.getDealerList()
      } else {
        this.$message.error(res.msg)
      }
    },
    editDealerArea(data) {
      this.editForm.areaId = data
    },
    addDealerArea(data) {
      this.addForm.areaId = data
    },
    updateDealer() {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.put('/ma/dealer/update', this.editForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getDealerList()
            this.editDialogVisible = false
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    initDealerCode(data) {
      this.queryInfo.query.dealerCode = data
    },
    searchList() {
      this.getDealerList()
    },
    clearCondition() {
      this.queryInfo.query.dealerCode = null
      this.queryInfo.query.state = null
      this.queryInfo.query.key = null
      this.$refs.dealerChild.clear()
      this.getDealerList()
    }

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getDealerList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }// 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.el-table {
  margin-top: 10px;
}
</style>
