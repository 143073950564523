<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>总结管理</el-breadcrumb-item>
      <el-breadcrumb-item>总结列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
<!--        <el-col :span="2">-->
<!--          <el-select-->
<!--            v-model="queryInfo.query.smallAreaId"-->
<!--            placeholder="小区经理"-->
<!--            @change="getPresetMattersList"-->
<!--            filterable-->
<!--            @click.native="handleClick"-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="item in smallAreaList"-->
<!--              :key="item.id"-->
<!--              :label="item.smallAreaName"-->
<!--              :value="item.id"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-col>-->
<!--        <el-col :span="2">-->
<!--        <el-select-->
<!--          v-model="queryInfo.query.dealerCodes"-->
<!--          placeholder="经销商"-->
<!--          @change="getPresetMattersList"-->
<!--          filterable-->
<!--          @click.native="handleClick1"-->
<!--        >-->
<!--          <el-option-->
<!--            v-for="item in dealerCodes"-->
<!--            :key="item.dealerCodes"-->
<!--            :label="item.dealerCodes"-->
<!--            :value="item.dealerCodes"-->
<!--          >-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--        </el-col>-->
<!--        <el-col :span="4.6">-->
<!--          <div class="block">-->
<!--            <span class="demonstration"></span>-->
<!--            <el-date-picker-->
<!--              v-model="queryInfo.query.createDate"-->
<!--              type="date"-->
<!--              value-format="yyyy-MM-dd HH:mm:ss"-->
<!--              default-time="23:59:59"-->
<!--              @change="getPresetMattersList"-->
<!--              placeholder="创建时间">-->
<!--            </el-date-picker>-->
<!--          </div>-->
<!--        </el-col>-->
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.type"
            @change="getPresetMattersList"
            placeholder="请选择类型"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5.5">
          <el-date-picker
            v-model="beginEndDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getPresetMattersList"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '00:00:00']"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="exportData">导出数据
          </el-button
          >
        </el-col>
      </el-row>
      <el-table :data="PersonalizedContentList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="小区经理" prop="smallManagerName"></el-table-column>
        <el-table-column
          width="560px"
          label="内容"
          prop="content"
        ></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="经销商" prop="dealerCodes"></el-table-column>
        <el-table-column
          width="200px"
          label="创建时间"
          prop="createTime"
        ></el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue'
import { Api } from '@/assets/js/api.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter },
  data() {
    var checkDealerOrSmall = (rule, value, cb) => {
      if (this.addForm.checkDealers.length === 0) {
        cb(new Error('请选择整改人'))
      }
      return cb()
    }
    var checkRole = (rule, value, cb) => {
      if (!this.addForm.roleId) {
        cb(new Error('请选择角色'))
      }
      return cb()
    }
    // 这里存放数据
    return {
      beginEndDate: null,
      typeList: [{
        label: '车展',
        value: 'AUTO'
      }, {
        label: '会议',
        value: 'MEETING'
      }, {
        label: '访店',
        value: 'SHOP'
      }],
      fullscreenLoading: false,
      fileList: [],
      smallAreaList: [],
      stateList: [],
      exportDialogVisible: false,
      haedUserList: [],
      presetTypeList: [],
      smallManagerList: [],
      bigManagerList: [],
      dealerCodes: [],
      addFormRules: {
        year: [
          {
            required: true,
            message: '请选择年月',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请填写整改内容',
            trigger: 'blur'
          }
        ],
        target: [
          {
            required: true,
            message: '请填写整改目标',
            trigger: 'blur'
          }
        ],
        deadline: [
          {
            required: true,
            message: '请选择截止时间',
            trigger: 'blur'
          }
        ],
        handlerId: [
          {
            required: true,
            message: '请选择下发人',
            trigger: 'blur'
          }
        ],
        typeId: [
          {
            required: true,
            message: '请选择整改类型',
            trigger: 'blur'
          }
        ],
        role: [{
          validator: checkRole,
          trigger: 'blur'
        }],
        dealerOrSmall: [{
          validator: checkDealerOrSmall,
          trigger: 'blur'
        }]

      },
      loading: false,
      PersonalizedContentList: [],
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          roleId: null,
          beginDate: null,
          endDate: null,
          type: null
        }
      },
      total: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async getSmallAreaList() {
      const { data: res } = await this.$http.get('/ma/base/getSmallAreaList', { params: { roleId: this.queryInfo.query.roleId } })
      this.smallAreaList = res.data
    },
    handleClick() {
      if (!this.queryInfo.query.roleId) {
        this.$message.info('请选择角色')
      } else {
        this.getSmallAreaList()
      }
    },
    handleClick1() {
      if (!this.queryInfo.query.smallAreaId) {
        this.$message.info('请选择小区经理')
      } else {
        this.getDealerCodes()
      }
    },
    initAddRole(data) {
      this.addForm.roleId = data
      this.getDealerCodes()
    },
    async getDealerCodes() {
      const { data: res } = await this.$http.get('/ma/base/dealerCodeBySmallAreaId', { params: { smallAreaId: this.queryInfo.query.smallAreaId } })
      this.dealerCodes = res.data
    },
    closeAddDialog() {
    },
    exportData() {
      if (!this.queryInfo.query.roleId) {
        this.$message.warning('请选择角色')
      } else
      if (this.beginEndDate == null) {
        this.$message.warning('请选择时间')
      } else
      if (this.beginEndDate) {
        this.queryInfo.query.beginDate = this.beginEndDate[0]
        this.queryInfo.query.endDate = this.beginEndDate[1]
        const url = Api.apiDomain + '/ma/personalized/exportData/' + this.queryInfo.query.roleId + '/' + this.queryInfo.query.type + '/' + this.queryInfo.query.beginDate + '/' + this.queryInfo.query.endDate
        window.open(url, '_self')
      }
      // const oriUrl = window.location.origin + window.location.pathname
      // const url = Api.apiDomain + '/ma/personalized/exportData/' + this.queryInfo.query.roleId + '/' + this.queryInfo.query.type + '/' + this.queryInfo.query.beginDate + '/' + this.queryInfo.query.endDate
      // const { data: res } = await this.$http.get('/ma/personalized/exportDataParam', {
      //   params: {
      //     roleId: this.queryInfo.query.roleId,
      //     endDate: this.queryInfo.query.endDate,
      //     beginDate: this.queryInfo.query.beginDate,
      //     type: this.queryInfo.query.type
      //   }
      // })
      // if (res.code === -20048) {
      //   this.$message.error(res.msg)
      // } else {
      // window.open(url, '_self')
      // }
    },
    initRole(data) {
      this.queryInfo.query.roleId = data
      this.getPresetMattersList()
    },
    async getPresetMattersList() {
      if (this.beginEndDate) {
        this.queryInfo.query.beginDate = this.beginEndDate[0]
        this.queryInfo.query.endDate = this.beginEndDate[1]
      } else {
        this.queryInfo.query.beginDate = null
        this.queryInfo.query.endDate = null
      }
      this.loading = true
      const { data: res } = await this.$http.post('/ma/personalized/content/list', this.queryInfo)
      this.PersonalizedContentList = res.data.content
      this.total = res.data.totalElements
      this.loading = false
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getPresetMattersList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getPresetMattersList()
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPresetMattersList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
/deep/ .el-table td.el-table__cell div {
  height: 100px;
  overflow: overlay;
}

.el-table {
  margin-top: 10px;
}

/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
  }
}
</style>
