<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>邮件公共管理</el-breadcrumb-item>
      <el-breadcrumb-item>邮件公告列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <el-select
              v-model="queryInfo.query.isEncry"
              placeholder="请选择类型"
              @change="getEmailList"
          >
            <el-option
                v-for="item in [
                { label: '定向', value: 'ENCRYPTION' },
                { label: '全网', value: 'COMMON' },
              ]"
                :key="item.label"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button
              type="primary"
              @click="syncEmail"
              element-loading-text="邮件同步中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              v-loading.fullscreen.lock="fullscreenLoading"
          >同步邮件
          </el-button
          >
        </el-col>
      </el-row>
      <el-table :data="emailList" stripe border>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="主题" prop="subject"></el-table-column>
        <el-table-column label="来自邮箱" prop="emailFrom"></el-table-column>
        <el-table-column label="时间" prop="receiveTime"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-popconfirm
                title="确定删除吗"
                icon="el-icon-info"
                icon-color="red"
                @confirm="deleteById(scope.row.id)"
            >
              <el-button
                  slot="reference"
                  type="danger"
                  v-show="scope.row.type === 'COMMON'"
              >删除
              </el-button>
            </el-popconfirm>
            <el-button
                type="primary"
                v-show="scope.row.type === 'ENCRYPTION'"
                @click="editRole(scope.row.id)"
            >分配权限
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[5, 10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>

    <el-dialog
        :close-on-click-modal="false"
        title="邮件查看权限分配"
        :visible.sync="roleDialogVisible"
        width="50%"
        @close="closeRoleDialog"
    >
      <el-form
          ref="addFormRef"
          :model="addForm"
          :rules="addFormRules"
          label-width="100px"
      >
        <el-form-item label="角色" prop="role">
          <saleOrAfter
              ref="roleAddChild"
              @role-child="initAddRole"
          ></saleOrAfter>
        </el-form-item>
        <el-form-item label="选择经销商" prop="dealerOrSmall">
          <el-tabs @tab-click="handleClick">
            <el-tab-pane label="经销商" name="first">
              <el-cascader
                  v-model="addForm.dealers"
                  :options="dealerList"
                  :props="{ multiple: true }"
                  collapse-tags
                  clearable
                  filterable
              ></el-cascader>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="选择小区" prop="dealerOrSmall">
          <el-tabs @tab-click="handleClick">
            <el-tab-pane label="小区经理" name="second">
              <el-cascader
                  v-model="addForm.smalls"
                  :options="smallList"
                  :props="{ multiple: true }"
                  clearable
                  filterable
              ></el-cascader>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="选择大区" prop="dealerOrSmall">
          <el-tabs @tab-click="handleClick">
            <el-tab-pane label="大区经理" name="third">
              <el-cascader
                  v-model="addForm.bigs"
                  :options="bigList"
                  :props="{ multiple: true }"
                  clearable
                  filterable
              ></el-cascader>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="选择总部" prop="dealerOrSmall">
          <el-tabs @tab-click="handleClick">
            <el-tab-pane label="总部" name="fourth">
              <el-cascader
                  v-model="addForm.heads"
                  :options="headList"
                  :props="{ multiple: true }"
                  clearable
                  filterable
              ></el-cascader>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue';

export default {

  // import引入的组件需要注入到对象中才能使用
  components: {SaleOrAfter},
  data() {
    // 这里存放数据
    return {
      headList: [],
      allList: {
        list: [],
        id: null
      },
      dealerList: [],
      smallList: [],
      bigList: [],
      addForm: {
        roleId: null,
        dealers: [],
        smalls: [],
        bigs: [],
        heads: []
      },
      addFormRules: {},
      roleDialogVisible: false,
      fullscreenLoading: false,
      emailList: [],
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          isEncry: null,
          roleId: null,
          key: null
        }
      },
      total: 0
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async addRole() {
      if (this.addForm.dealers.length === 0 && this.addForm.smalls.length === 0 && this.addForm.bigs.length === 0 && this.addForm.heads.length === 0) {
        this.$message.warning('请选择人员');
      } else {
        this.getValues(this.addForm.dealers);
        this.getValues(this.addForm.smalls);
        this.getValues(this.addForm.bigs);
        this.getValues(this.addForm.heads);
        const {data: res} = await this.$http.post('/ma/email/addRole', this.allList);
        if (res.code === 0) {
          this.$message.success(res.msg);
          this.closeRoleDialog();
          this.getEmailList();
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    /* async deleteById(id) {
      const { data: res } = await this.$http.delete(`/ma/email/${id}`)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.getEmailList()
      } else {
        this.$message.error(res.msg)
      }
    }, */
    getValues(items) {
      items.map((item) => {
        this.allList.list.push(item[item.length - 1]);
      });
    },
    async getSmallList() {
      const {data: res} = await this.$http.get('/ma/user/getSmallAreaCascader', {params: {roleId: this.addForm.roleId}});
      this.smallList = res.data;
    },
    async getHeadUserList() {
      const {data: res} = await this.$http.get('/ma/user/headCascaderList');
      this.headList = res.data;
    },
    async getBigCascaderList() {
      const {data: res} = await this.$http.get('/ma/user/getBigCascaderList', {params: {roleId: this.addForm.roleId}});
      this.bigList = res.data;
    },
    async getDealerList() {
      const {data: res} = await this.$http.get('/ma/base/bigSmallDealerList', {params: {roleId: this.addForm.roleId}});
      this.dealerList = res.data;
    },
    initAddRole(data) {
      this.addForm.roleId = data;
      this.getDealerList();
      this.getSmallList();
      this.getBigCascaderList();
      this.getHeadUserList();
    },
    handleClick() {
      if (!this.addForm.roleId) {
        this.$message.info('请选择角色');
      }
    },
    editRole(id) {
      this.roleDialogVisible = true;
      this.allList.id = id;
    },
    async getEmailList() {
      const {data: res} = await this.$http.post('/ma/email', this.queryInfo);
      this.emailList = res.data.content;
      this.total = res.data.totalElements;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getEmailList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getEmailList();
    },
    initRole(data) {
      this.queryInfo.query.roleId = data;
      this.getEmailList();
    },
    async syncEmail() {
      this.fullscreenLoading = true;
      const {data: res} = await this.$http.get('/ma/email/syncEmail');
      this.fullscreenLoading = false;
      if (res.code === 0) {
        this.$message.success(res.msg);
        this.getEmailList();
      } else {
        this.$message.error(res.msg);
      }
    },
    closeRoleDialog() {
      this.addForm.dealers = [];
      this.addForm.smalls = [];
      this.addForm.bigs = [];
      this.addForm.heads = [];
      this.dealerList = [];
      this.smallList = [];
      this.allList.list = [];
      this.headList = [];
      this.bigList = [];
      this.roleDialogVisible = false;
      this.$refs.roleAddChild.clear();
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getEmailList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  } // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.el-cascader {
  width: 80%;
}
</style>
