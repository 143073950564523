<template>
  <div class="root-container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/questionnaire' }">问卷管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{ isEdit ? "编辑" : "创建" }}问卷</el-breadcrumb-item>
    </el-breadcrumb>
    <el-container class="page-main" style="height: calc(100% - 29px);" v-loading="loading">
      <div class="page-aside">
        <div class="classify-wrap" v-for="group in questionTypes" :key="group.type">
          <div class="classify-title"><i class="icon el-icon-caret-bottom"></i>{{ group.name }}</div>
          <div class="classify-list">
            <button class="classify-item" v-for="child in group.children" :key="child.type" :disabled="form.status" @click="addQuestion(child.type)">
              <i class="icon iconfont" :class="child.icon"></i>{{ child.name }}
            </button>
          </div>
        </div>
      </div>
      <div class="questionnaire-container">
        <div class="questionnaire-header">
          <el-tooltip v-if="form.status" effect="dark" content="已发布问卷不可编辑" placement="top">
            <el-button style="border-radius: 0;" type="primary" icon="el-icon-check" disabled>完成编辑</el-button>
          </el-tooltip>
          <el-button v-else style="border-radius: 0;" type="primary" icon="el-icon-check" @click="submitQuestionnaire">完成编辑</el-button>
        </div>
        <div class="questionnaire-main">
          <el-form class="questionnaire-inner" ref="form" :rules="rules" :model="form" size="mini" :disabled="form.status">
            <el-form-item prop="questionnaireName">
              <el-input class="questionnaire-title" type="textarea" autosize clearable v-model="form.questionnaireName" placeholder="请输入问卷主题"></el-input>
            </el-form-item>
            <div v-for="(question, questionIndex) in form.questionList" :key="questionIndex">
              <div class="question-item">
                <div class="question-type">
                  <span></span>
                  <span></span>
                </div>
                <el-form-item :prop="`questionList.${questionIndex}.question`" :rules="rules.questionName">
                  <div class="question-header">
                    <el-input class="questionnaire-title" placeholder="请输入题目" clearable v-model="question.question"></el-input>
                    <div class="question-actions">
                      <el-button v-if="form.questionList.length > 1" size="mini" plain icon="el-icon-delete" @click="deleteQuestion(questionIndex)"></el-button>
                      <el-button size="mini" plain icon="el-icon-sort-up" @click="forwardQuestion(questionIndex)"></el-button>
                      <el-button size="mini" plain icon="el-icon-sort-down" @click="backwardQuestion(questionIndex)"></el-button>
                    </div>
                  </div>
                </el-form-item>
                <template v-if="['single', 'multiple'].includes(question.type)">
                  <draggable v-model="question.options" ghost-class="question-option-ghost">
                    <transition-group>
                      <div v-for="(option, optionIndex) in question.options" :key="optionIndex" class="question-option">
                        <div class="question-option-inner">
                          <el-form-item :prop="`questionList.${questionIndex}.options.${optionIndex}.value`" :rules="rules.option">
                            <el-input :placeholder="`选项${optionIndex + 1}`" clearable v-model="option.value">
                              <el-checkbox v-if="question.type === 'multiple'" slot="prepend"></el-checkbox>
                              <i v-if="question.type === 'single'" class="question-option-radio" slot="prepend"></i>
                            </el-input>
                          </el-form-item>
                        </div>
                        <div class="question-option-actions">
                          <el-button
                            v-if="question.options.length > 1"
                            size="mini"
                            plain
                            icon="el-icon-delete"
                            @click="deleteOption(questionIndex, optionIndex)"
                          ></el-button>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>

                  <el-button size="mini" @click="addOption(questionIndex)">添加选项</el-button>
                </template>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { scrollIntoFormError } from "@/assets/js/utils";

const questionTypes = [
  {
    name: "选择题",
    type: "choice",
    children: [
      {
        name: "单选",
        type: "single",
        icon: "icon-danxuanxuanzhong",
      },
      {
        name: "多选",
        type: "multiple",
        icon: "icon-duoxuanxuanzhong",
      },
    ],
  },
  {
    name: "填空题",
    type: "input",
    children: [
      {
        name: "简答题",
        type: "answer",
        icon: "icon-danhangshurukuang",
      },
      {
        name: "手机号",
        type: "phone",
        icon: "icon-shoujihao",
      },
      {
        name: "身份证",
        type: "idCard",
        icon: "icon-shenfenzhengkapian",
      },
      {
        name: "数字",
        type: "digit",
        icon: "icon-shuzi",
      },
      {
        name: "日期",
        type: "date",
        icon: "icon-riqi",
      },
    ],
  },
];

export default {
  components: {
    draggable,
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      questionTypes,
      form: {
        questionnaireName: "",
        status: false,
        questionList: [
          {
            type: "multiple",
            question: "",
            options: [
              {
                value: "",
              },
              {
                value: "",
              },
            ],
          },
        ],
      },
      rules: {
        questionnaireName: [{ required: true, message: "请输入问卷主题", trigger: "change" }],
        questionName: [{ required: true, message: "请输入题目", trigger: "change" }],
        option: [{ required: true, message: "请输入选项", trigger: "change" }],
      },
    };
  },
  created() {
    const { id } = this.$route.query;

    if (id) {
      this.isEdit = true;
      this.getDetails(id);
    }
  },
  methods: {
    async getDetails(id) {
      this.loading = true;
      const { data: res } = await this.$http.get(`/questionnaire/findOne/${id}`);
      this.loading = false;

      // 格式化题目信息
      const questionTypes = ["phoneModels", "idCardModels", "timesModels", "numberModels", "answersQuestionModels", "questionnaireAnswerModels"];
      const questionnaire = res.data;
      const _questionnaire = [];
      for (const key in questionnaire) {
        if (Object.hasOwnProperty.call(questionnaire, key)) {
          const questions = questionnaire[key];
          if (questionTypes.includes(key)) {
            questions.forEach((question) => {
              if (key === "phoneModels") {
                // 手机号类型题
                _questionnaire.push({
                  type: "phone",
                  number: question.count,
                  question: question.phone,
                });
              } else if (key === "idCardModels") {
                // 身份证号类型题
                _questionnaire.push({
                  type: "idCard",
                  number: question.count,
                  question: question.idCard,
                });
              } else if (key === "timesModels") {
                // 时间类型题
                _questionnaire.push({
                  type: "date",
                  number: question.count,
                  question: question.times,
                });
              } else if (key === "numberModels") {
                // 数字类型题
                _questionnaire.push({
                  type: "digit",
                  number: question.count,
                  question: question.number,
                });
              } else if (key === "answersQuestionModels") {
                // 问答题题
                _questionnaire.push({
                  type: "answer",
                  number: question.count,
                  question: question.answerQuestion,
                });
              } else if (key === "questionnaireAnswerModels" && question.isOption) {
                // 多选题
                _questionnaire.push({
                  type: "multiple",
                  number: question.count,
                  question: question.title,
                  options: question.option.map((item) => ({ value: item })),
                });
              } else if (key === "questionnaireAnswerModels" && !question.isOption) {
                // 单选题
                _questionnaire.push({
                  type: "single",
                  number: question.count,
                  question: question.title,
                  options: question.option.map((item) => ({ value: item })),
                });
              }
            });
          }
        }
      }
      _questionnaire.sort((a, b) => a.number - b.number);

      const { userName } = JSON.parse(window.sessionStorage.getItem("userInfo"));
      this.form = {
        id: questionnaire.id, //问卷id
        publishName: userName, //发布人
        status: questionnaire.status, //发布状态（false代表未发布，true代表已发布）
        questionnaireName: questionnaire.questionName,
        questionList: _questionnaire,
      };
    },
    addQuestion(type) {
      if (["single", "multiple"].includes(type)) {
        this.form.questionList.push({
          type,
          question: "",
          options: [
            {
              value: "",
            },
            {
              value: "",
            },
          ],
        });
      } else if (["answer", "phone", "idCard", "digit", "date"].includes(type)) {
        this.form.questionList.push({
          type,
          question: "",
        });
      }
    },
    deleteQuestion(questionIndex) {
      this.form.questionList.splice(questionIndex, 1);
    },
    forwardQuestion(questionIndex) {
      if (questionIndex === 0) {
        const firstElement = this.form.questionList.shift();
        this.form.questionList.push(firstElement);
      } else {
        const element = this.form.questionList.splice(questionIndex, 1)[0];
        this.form.questionList.splice(questionIndex - 1, 0, element);
      }
    },
    backwardQuestion(questionIndex) {
      if (questionIndex === this.form.questionList.length - 1) {
        const lastElement = this.form.questionList.pop();
        this.form.questionList.unshift(lastElement);
      } else {
        const element = this.form.questionList.splice(questionIndex, 1)[0];
        this.form.questionList.splice(questionIndex + 1, 0, element);
      }
    },
    addOption(questionIndex) {
      this.form.questionList[questionIndex].options.push({
        value: "",
      });
    },
    deleteOption(questionIndex, optionIndex) {
      this.form.questionList[questionIndex].options.splice(optionIndex, 1);
    },
    submitQuestionnaire() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = {
            questionName: this.form.questionnaireName,
            phoneModels: [], // 手机号类型题
            idCardModels: [], // 身份证号类型题
            timesModels: [], // 时间类型题
            numberModels: [], // 数字类型题
            answersQuestionModels: [], // 问答题题
            questionnaireAnswerModels: [], // 选择题题
          };
          this.form.questionList.forEach((question, questionIndex) => {
            if (question.type === "single") {
              params.questionnaireAnswerModels.push({
                count: questionIndex + 1,
                isOption: false,
                title: question.question,
                option: question.options.map((option) => option.value),
              });
            } else if (question.type === "multiple") {
              params.questionnaireAnswerModels.push({
                count: questionIndex + 1,
                isOption: true,
                title: question.question,
                option: question.options.map((option) => option.value),
              });
            } else if (question.type === "answer") {
              params.answersQuestionModels.push({
                count: questionIndex + 1,
                answerQuestion: question.question,
              });
            } else if (question.type === "phone") {
              params.phoneModels.push({
                count: questionIndex + 1,
                phone: question.question,
              });
            } else if (question.type === "idCard") {
              params.idCardModels.push({
                count: questionIndex + 1,
                idCard: question.question,
              });
            } else if (question.type === "digit") {
              params.numberModels.push({
                count: questionIndex + 1,
                number: question.question,
              });
            } else if (question.type === "date") {
              params.timesModels.push({
                count: questionIndex + 1,
                times: question.question,
              });
            }
          });

          this.loading = true;
          let res = null;
          if (this.isEdit) {
            Object.assign(params, {
              id: this.form.id, //问卷id
              publishName: this.form.publishName, //发布人
              status: this.form.status, //发布状态（false代表未发布，true代表已发布）
            });
            const { data } = await this.$http.put("/questionnaire/modifyAnswer", params);
            res = data;
          } else {
            const { data } = await this.$http.post("/questionnaire/create", params);
            res = data;
          }
          this.loading = false;

          if (res.code !== 0) {
            this.$message.error(res.msg);
          } else {
            this.$message.success(this.isEdit ? "修改成功" : "创建成功");
            this.$router.push("/questionnaire");
          }
        } else {
          scrollIntoFormError();
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./styles/iconfont.css";

.root-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-main {
  flex: 1;
  gap: 20px;
}

.page-aside {
  box-sizing: border-box;
  width: 270px;
  padding: 20px;
  overflow-y: auto;
  border: 1px solid #ebeef5;
  background-color: #fff;
  border-radius: 5px;
  color: #303133;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.classify-wrap {
  .classify-title {
    user-select: none;
    min-height: 30px;
    line-height: 30px;
    cursor: pointer;
    font-size: 14px;
    color: #000;
    font-weight: 600;

    .icon {
      margin: 0 4px;
      font-size: 18px;
    }
  }

  .classify-list {
    display: flex;
    flex-wrap: wrap;
  }

  .classify-item {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    width: 50%;
    height: 26px;
    line-height: 26px;
    background: #fff;
    border-radius: 13px;
    font-size: 13px;
    color: #666;
    display: flex;
    align-items: center;

    &:disabled {
      cursor: not-allowed;
    }

    .icon {
      color: #0095ff;
      font-size: 18px;
      margin: 0 4px;
      vertical-align: middle;
    }

    &:hover {
      background-color: #0095ff;

      .icon {
        color: #fff;
      }
    }
  }
}

.questionnaire-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 5px;
  overflow: hidden;
}

.questionnaire-header {
  background-color: rgb(49, 49, 49);
  box-shadow: 0px 2px 6.88px 1.12px rgba(0, 0, 0, 0.05);
  padding: 0 calc(15% + 20px);
  display: flex;
  justify-content: flex-end;
  height: 46px;
}

.questionnaire-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  border: 1px solid #ebeef5;
  background-color: #fff;
  color: #303133;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.questionnaire-inner {
  width: 70%;
  height: fit-content;
  margin: 0 auto;
}

.question-header {
  display: flex;
  gap: 10px;
}

.questionnaire-title {
  flex: 1;
}

.questionnaire-title /deep/ .el-textarea__inner {
  font-size: 24px;
  font-family: "Source Han Sans CN";
  color: rgb(95, 157, 248);
  text-align: center;
}

.question-item {
  position: relative;
  border: 1px solid #ebeef5;
  background-color: #fff;
  color: #303133;
  transition: 0.3s;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;

  .question-actions {
    display: none;
  }

  &:hover .question-actions {
    display: block;
  }

  .question-option-radio {
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    border-radius: 50%;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: #fff;
    vertical-align: middle;
  }
}

.question-item /deep/ .el-input-group__prepend {
  pointer-events: none;
}

.question-option {
  display: flex;
  gap: 10px;
  cursor: move;

  &-ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  &-inner {
    flex: 1;
  }

  &-actions {
    display: none;
  }

  &:hover &-actions {
    display: block;
  }
}
</style>
