/**页面滚动到某个元素处
 *
 * @param selector CSS选择器
 * @param delay 延迟（毫秒）
 */
export const scrollIntoView = (selector, delay = 0) => {
  setTimeout(() => {
    const element = document.querySelector(selector);

    element.style.scrollMarginTop = "18px";
    document.querySelector(selector).scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }, delay);
};

/**页面滚动到form表单校验错误提示处
 *
 * @description 常用于element-form的validate事件中
 */
export const scrollIntoFormError = () => {
  scrollIntoView(".el-form-item.is-error");
};