<template>
  <div class=''>
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>通讯录管理</el-breadcrumb-item>
      <el-breadcrumb-item>通讯录列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="1.5">
          <el-button type="primary" @click="addDialogVisible = true"
          >新增</el-button
          >
        </el-col>
        <el-col :span="2">
        <el-button type="primary" @click="importDialogVisible = true"
        >导入通讯录</el-button
        >
        </el-col>
      </el-row>
      <el-table :data="addressList" stripe border>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="种类划分" prop="kindDivide"></el-table-column>
        <el-table-column label="职位标题" prop="positionTitle"></el-table-column>
        <el-table-column label="职位英文" prop="positionName"></el-table-column>
        <el-table-column label="中文名" prop="nameCn"> </el-table-column>
        <el-table-column label="手机" prop="phone"> </el-table-column>
        <el-table-column label="邮箱" prop="email"> </el-table-column>
        <el-table-column label="工作范畴" prop="workScope"> </el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" @click="edit(scope.row)">编辑</el-button>
            <el-popconfirm
              style="margin-left: 10px"
              title="确定删除吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="deleteById(scope.row.id)"
            >
              <el-button slot="reference" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :close-on-click-modal="false"
      title="通讯录新增"
      @close="closeAddDialog"
      :visible.sync="addDialogVisible"
      width="50%"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="editFormRules"
        label-width="100px"
      >
        <el-form-item label="角色" prop="role">
          <saleOrAfter
            ref="roleAddChild"
            @role-child="initAddRole"
          ></saleOrAfter>
        </el-form-item>
        <el-form-item label="种类划分" prop="kindDivide">
          <el-input type="input" v-model="addForm.kindDivide"></el-input>
        </el-form-item>
        <el-form-item label="职位标题" prop="positionTitle">
          <el-input type="input" v-model="addForm.positionTitle"></el-input>
        </el-form-item>
        <el-form-item label="职位英文" prop="positionName">
          <el-input type="input" v-model="addForm.positionName"></el-input>
        </el-form-item>
        <el-form-item label="中文名" prop="nameCn">
          <el-input type="input" v-model="addForm.nameCn"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input type="input" v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input type="input" v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="工作范畴" prop="workScope">
          <el-input type="input" v-model="addForm.workScope"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addAddress">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="通讯录修改"
      @close="closeEditDialog"
      :visible.sync="editDialogVisible"
      width="50%"
    >
      <el-form
        ref="editFormRef"
        :model="editForm"
        :rules="editFormRules"
        label-width="100px"
      >
        <el-form-item label="种类划分" prop="kindDivide">
          <el-input type="input" v-model="editForm.kindDivide"></el-input>
        </el-form-item>
        <el-form-item label="职位标题" prop="positionTitle">
          <el-input type="input" v-model="editForm.positionTitle"></el-input>
        </el-form-item>
        <el-form-item label="职位英文" prop="positionName">
          <el-input type="input" v-model="editForm.positionName"></el-input>
        </el-form-item>
        <el-form-item label="中文名" prop="nameCn">
          <el-input type="input" v-model="editForm.nameCn"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input type="input" v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input type="input" v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="工作范畴" prop="workScope">
          <el-input type="input" v-model="editForm.workScope"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAddress">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="导入通讯录"
      :visible.sync="importDialogVisible"
      center
      width="22%"
    >
      <el-form>
        <el-form-item label="角色" prop="roleId">
          <saleOrAfter
            ref="roleAddChild"
            @role-child="initImportRole"
          ></saleOrAfter>
        </el-form-item>
      </el-form>
      <el-upload
        class="upload-demo"
        ref="upload"
        drag
        :file-list="fileList"
        :auto-upload="false"
        :before-upload="beforeUpload"
        :limit="1"
        action=""
        :on-exceed="handleExceed"
        :http-request="uploadAddress"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>

      <span slot="footer" class="dialog-footer">
        <el-button @click="importDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          element-loading-text="系统下发中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-loading.fullscreen.lock="fullscreenLoading"
          @click="submitUpload"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '@/components/common/SaleOrAfter'

export default {
// import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter },
  data() {
    var checkRole = (rule, value, cb) => {
      if (!this.addForm.roleId) {
        cb(new Error('请选择角色'))
      }
      return cb()
    }
    // 这里存放数据
    return {
      addressList: [],
      importRoleId: null,
      addDialogVisible: false,
      importDialogVisible: false,
      fileList: [],
      addForm: {
        roleId: null,
        kindDivide: null,
        positionTitle: null,
        positionName: null,
        nameCn: null,
        phone: null,
        email: null,
        workScope: null
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          roleId: null
        }
      },
      editForm: {
        id: null,
        roleId: null,
        kindDivide: null,
        positionTitle: null,
        positionName: null,
        nameCn: null,
        phone: null,
        email: null,
        workScope: null
      },
      editFormRules: {
        role: [{ validator: checkRole, trigger: 'blur' }],

        kindDivide: [
          { required: true, message: '请填写种类划分', trigger: 'blur' }
        ],
        positionTitle: [
          { required: true, message: '请填写职位标题', trigger: 'blur' }
        ],
        positionName: [
          { required: true, message: '请填写职位英文', trigger: 'blur' }
        ],
        nameCn: [
          { required: true, message: '请填写中文名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请填写手机', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请填写邮箱', trigger: 'blur' }
        ],
        workScope: [
          { required: true, message: '请填写工作范畴', trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      total: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    initImportRole(data) {
      this.importRoleId = data
    },
    initAddRole(data) {
      this.addForm.roleId = data
    },
    initRole(data) {
      this.queryInfo.query.roleId = data
      this.getAddressList()
    },
    async deleteById(id) {
      const { data: res } = await this.$http.delete(`/ma/address/${id}`)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.getAddressList()
      } else {
        this.$message.error(res.msg)
      }
    },
    beforeUpload(file) {
      // 判断文件格式
      const name = file.name.split('.').pop()
      if (name !== 'xlsx' && name !== 'xls') {
        this.$message.error('只能选择excel文件')
        return false
      }
    },
    async uploadAddress(f) {
      const param = new FormData()
      param.append('file', f.file)
      param.append('roleId', this.importRoleId)
      this.fullscreenLoading = true
      const { data: res } = await this.$http.post('ma/address/import', param)
      this.fullscreenLoading = false

      if (res.code !== 0) {
        this.$message.error(res.msg)
      } else {
        this.$message.success('导入成功')
        this.fileList = []
        this.exportDialogVisible = false
        this.getAddressList()
      }
    },
    // 上传文件个数超过定义的数量
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 个文件')
    },
    async updateAddress() {
      this.$refs.editFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.put('/ma/address', this.editForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getAddressList()
            this.editDialogVisible = false
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async addAddress() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/ma/address', this.addForm)
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getAddressList()
            this.addDialogVisible = false
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getAddressList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getAddressList()
    },
    async getAddressList() {
      const { data: res } = await this.$http.post('ma/address/list', this.queryInfo)
      this.addressList = res.data.content
      this.total = res.data.totalElements
    },
    submitUpload() {
      this.$nextTick(() => {
        this.$refs.upload.submit()
      })
    },
    edit(data) {
      console.log(data)
      this.editForm.id = data.id
      this.editForm.roleId = data.roleId
      this.editForm.kindDivide = data.kindDivide
      this.editForm.positionTitle = data.positionTitle
      this.editForm.positionName = data.positionName
      this.editForm.nameCn = data.nameCn
      this.editForm.phone = data.phone
      this.editForm.email = data.email
      this.editForm.workScope = data.workScope
      this.editDialogVisible = true
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAddressList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
</style>
