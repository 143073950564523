<template>
  <div class="login_container">
    <div class="login_box">
      <!--头像区域 -->
      <div class="avatar_box">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="title">Volvo DSS 运维系统</div>

      <!--登录表单区域 -->
      <el-form ref="loginFormRef" :rules="loginFormRules" class="login_form" :model="loginForm">
        <!--用户名 -->
        <el-form-item prop="userName">
          <el-input v-model="loginForm.userName" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!--密码 -->
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <!--按钮区域 -->
        <el-form-item class="btns">
          <el-button @click="login" type="primary">登录</el-button>
          <el-button @click="resetLoginForm" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 登录表单的数据绑定对象
      loginForm: {
        userName: "",
        password: "",
      },
      // 表单验证规则
      loginFormRules: {
        userName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      console.log(this);
      this.$refs.loginFormRef.validate(async (valid, object) => {
        if (valid) {
          const { data: res } = await this.$http.post("/ma/base/login", this.loginForm);
          if (res.code !== 0) {
            this.$message.error(res.msg);
          } else {
            this.$message.success("登陆成功");
            window.sessionStorage.setItem("token", res.data.token);
            window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
            this.$router.push("/home");
          }
        }
      });
    },
    handleKeyDown(e) {
      let key = null;
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        this.login();
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.handleKeyDown, true); // 开启监听键盘按下事件
  },
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}
.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .avatar_box {
    height: 130px;
    width: 130px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);
    font: bold 18px arial, sans-serif;
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>
