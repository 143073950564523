<!--  -->
<template>
  <div class="">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>报表管理</el-breadcrumb-item>
      <el-breadcrumb-item>打卡数据</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <el-select
              v-model="queryInfo.query.roleId"
              placeholder="请选择角色"
              @change="selectPositionForCheckIn"
          >
            <el-option
                v-for="item in  [
                  { label: '94b5fb45-64ba-11ed-8563-00ffbe0ccf45', value: '直售' },
                { label: 'add3f4f8-bf50-11ea-8431-00163e009fe8', value: '管理员' },
                { label: 'add72ea8-bf50-11ea-8431-00163e009fe8', value: '总部' },
                { label: 'add9cf5e-bf50-11ea-8431-00163e009fe8', value: '销售' },
                { label: 'addd0c07-bf50-11ea-8431-00163e009fe8', value: '售后' }
              ]"
                :key="item.label"
                :label="item.value"
                :value="item.label"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select
              v-model="queryInfo.query.position"
              placeholder="请选择职位"
              @change="getCheckInList"
          >
            <el-option
                v-for="item in positionList"
                :key="item.label"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5.5">
          <el-date-picker
              v-model="beginEndDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getCheckInList"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '00:00:00']"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-input
              placeholder="请输入查询关键字"
              width="20px"
              v-model="queryInfo.query.key"
              type="text"
          >
            <el-button
                slot="append"
                icon="el-icon-search"
                @click="getCheckInList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="exportData">导出</el-button>
        </el-col>
      </el-row>
      <el-table :data="checkInList" stripe border>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="经销商" prop="dealerCode"></el-table-column>
        <el-table-column label="位置" prop="location"></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="用户" prop="nameCn"></el-table-column>
        <el-table-column label="打卡时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="260px">
          <template slot-scope="scope">
            <el-popconfirm
                style="margin-left: 10px"
                title="确定删除吗"
                icon="el-icon-info"
                icon-color="red"
                @confirm="deleteCheckIn(scope.row.id)"
            >
              <el-button slot="reference" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue';
import {Api} from '@/assets/js/api.js';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {SaleOrAfter},
  data() {
    // 这里存放数据
    return {
      positionList: [],
      total: null,
      checkInList: [],
      beginEndDate: null,
      endDate: null,
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          key: null,
          position: null,
          beginDate: null,
          endDate: null,
          roleId: null
        }
      }
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    exportData() {
      if (this.queryInfo.query.roleId == null) {
        this.$message.warning('请选择角色');
      } else if (this.beginEndDate == null) {
        this.$message.warning('请选择时间');
      } else if (this.beginEndDate) {
        this.queryInfo.query.beginDate = this.beginEndDate[0];
        this.queryInfo.query.endDate = this.beginEndDate[1];
        const url = Api.apiDomain + '/ma/calender/checkIn/exportData/' + this.queryInfo.query.roleId + '/' + this.queryInfo.query.beginDate + '/' + this.queryInfo.query.endDate + '/' + this.queryInfo.query.position;
        window.open(url, '_self');
      }
    },
    async selectPositionForCheckIn() {
      const {data: res} = await this.$http.get('/ma/base/selectPositionForCheckIn', {params: {roleId: this.queryInfo.query.roleId}});
      this.positionList = res.data;
    },
    async deleteCheckIn(id) {
      const {data: res} = await this.$http.delete(`ma/calender/checkIn/${id}`);
      if (res.code === 0) {
        this.$message.success(res.msg);
        this.getCheckInList();
      } else {
        this.$message.error(res.msg);
      }
    },
    initRole(data) {
      this.queryInfo.query.roleId = data;
      this.getCheckInList();
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.loading = true;
      this.getCheckInList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getCheckInList();
    },
    async getCheckInList() {
      if (this.beginEndDate) {
        this.queryInfo.query.beginDate = this.beginEndDate[0];
        this.queryInfo.query.endDate = this.beginEndDate[1];

        console.log(this.queryInfo.query.beginDate);
      } else {
        this.queryInfo.query.beginDate = null;
        this.queryInfo.query.endDate = null;
      }
      const {data: res} = await this.$http.post('ma/calender/checkIn/list', this.queryInfo);
      this.checkInList = res.data.content;
      this.total = res.data.totalElements;
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCheckInList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {
  }, // 生命周期 - 创建之前
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
  } // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
</style>
