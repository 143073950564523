<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>报表管理</el-breadcrumb-item>
      <el-breadcrumb-item>走访数据</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row type="flex" :gutter="10">
        <el-col :span="2">
          <saleOrAfter ref="roleChild" @role-child="initRole"></saleOrAfter>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.type"
            @change="getCalenderList"
            placeholder="请选择类型"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5.5">
            <el-date-picker
              v-model="beginEndDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getCalenderList"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '00:00:00']"
            >
            </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入查询关键字"
            width="20px"
            v-model="queryInfo.query.key"
            type="text"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getCalenderList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="exportData">导出</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="calenderList" stripe border>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="小区经理" prop="name"></el-table-column>
        <el-table-column label="走访类型" prop="type"></el-table-column>
        <el-table-column label="事件类型" prop="checkType"></el-table-column>
        <el-table-column label="事件内容" prop="content"></el-table-column>
        <el-table-column label="走访时间" prop="time"></el-table-column>
        <el-table-column label="填写时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="260px">
          <template slot-scope="scope">
            <el-popconfirm
              style="margin-left: 10px"
              title="确定删除吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="deleteCalender (scope.row.id)"
            >
              <el-button slot="reference" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[5, 10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import SaleOrAfter from '../common/SaleOrAfter.vue'
import { Api } from '@/assets/js/api.js'

export default {

  // import引入的组件需要注入到对象中才能使用
  components: { SaleOrAfter },
  data() {
    // 这里存放数据
    return {
      beginEndDate: null,
      calenderList: [],
      typeList: [{
        label: '计划',
        value: 'PLAN'
      }, {
        label: '实际',
        value: 'ACTUAL'
      }],
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        query: {
          roleId: null,
          key: null,
          beginDate: null,
          endDate: null,
          type: null
        }
      },
      total: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    exportData() {
      if (this.queryInfo.query.roleId == null) {
        this.$message.warning('请选择角色')
      } else
      if (this.queryInfo.query.type == null) {
        this.$message.warning('请选择类型')
      } else
      if (this.beginEndDate == null) {
        this.$message.warning('请选择时间')
      } else
      if (this.beginEndDate) {
        this.queryInfo.query.beginDate = this.beginEndDate[0]
        this.queryInfo.query.endDate = this.beginEndDate[1]
        const url = Api.apiDomain + '/ma/calender/exportData/' + this.queryInfo.query.roleId + '/' + this.queryInfo.query.type + '/' + this.queryInfo.query.beginDate + '/' + this.queryInfo.query.endDate
        window.open(url, '_self')
      }
    },
    initRole(data) {
      this.queryInfo.query.roleId = data
      this.getCalenderList()
    },
    selectYear() {
      this.queryInfo.query.month = null
      this.getCalenderList()
    },
    async getCalenderList() {
      if (this.beginEndDate) {
        this.queryInfo.query.beginDate = this.beginEndDate[0]
        this.queryInfo.query.endDate = this.beginEndDate[1]
      } else {
        this.queryInfo.query.beginDate = null
        this.queryInfo.query.endDate = null
      }
      const { data: res } = await this.$http.post('ma/calender/list', this.queryInfo)
      this.calenderList = res.data.content
      this.total = res.data.totalElements
    },
    // pageSize改变
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.loading = true
      this.getCalenderList()
    },
    // pageNum 改变
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getCalenderList()
    },
    async deleteCalender(id) {
      const { data: res } = await this.$http.delete(`ma/calender/${id}`)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.getCalenderList()
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCalenderList()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
</style>
